import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'constants/index';
import Input from 'UiKit/Input';
import Text from 'UiKit/Text';
import ModalComponent from 'UiKit/Modal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { formatCardNumber, removeSpaces } from 'utils/functions';
import { selectCodeDetails, selectCodeIsFetching } from 'store/code';
import { mergeCards } from 'store/code/asyncActions';
import PinCodeVerificationModal from './pincodeVerification';
import ModalWrapper from './modalWrapper';

const CombineCardsModal = ({ closeModals }: { closeModals: () => void }) => {
  const [t] = useTranslation();
  const [value, setValue] = useState<string>('');
  const [showPinCodeModal, setShowPinCodeModal] = useState<boolean>(false);
  const codeData = useAppSelector(selectCodeDetails);
  const status = useAppSelector(selectCodeIsFetching);
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    setShowPinCodeModal(false);
    let params = {
      code: codeData.code,
      gift_code: Number(removeSpaces(value)),
    };
    dispatch(mergeCards(params));
  };

  return (
    <ModalWrapper
      title={t('combineTwoCards')}
      btnLoding={status}
      btnDisabled={value.length < 14 || status}
      closeModals={closeModals}
      onSubmit={() => setShowPinCodeModal(true)}
      btnText="connectTheCards"
    >
      <Text textAlign={'center'}>{t('combineTwoCardsMessage')}</Text>

      <Input
        id="mergeInput"
        defaultValue={value}
        maxLength={14}
        onChange={e => {
          e.target.value = formatCardNumber(e.target.value);
          setValue(e.target.value);
        }}
        backgroundColor={Colors.ALICE_BLUE}
      />
      <ModalComponent isVisible={showPinCodeModal} setVisible={() => setShowPinCodeModal(false)}>
        <PinCodeVerificationModal closeModals={() => setShowPinCodeModal(false)} onSubmit={handleSubmit} />
      </ModalComponent>
    </ModalWrapper>
  );
};
export default CombineCardsModal;
