import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Text from 'UiKit/Text';
import CodesList from './codeslist';
import { useAppSelector } from 'store/hooks';
import { selectCodeDetails } from 'store/code';
import { displayDate, isCardExpired } from 'utils/functions';
import { FontSize, FontWeight, FontFamily, Status } from 'constants/index';
import ButtonsWrapper from './buttonsWrapper';
import { selectMuseumData } from 'store/museum';
import { selectLoginStatus } from 'store/auth';
import MuseumList from './museumList';

const Main = () => {
  const [t] = useTranslation();
  const museumData = useAppSelector(selectMuseumData);
  const codeDetails = useAppSelector(selectCodeDetails);
  const adminStatus = useAppSelector(selectLoginStatus);
  return (
    <Container>
      <BodyWrapper>
        {museumData && (
          <Text fontSize={FontSize.THIRTY_TWO} fontWeight={FontWeight.BOLD} fontFamily={FontFamily.BOLD}>
            {`${museumData?.data.name} (${museumData?.data.id})`}
          </Text>
        )}
        {adminStatus === Status.SUCCEEDED && <MuseumList />}
        <CodesList />
        {codeDetails ? (
          !codeDetails.expires_at ? (
            <Text className="expiryDate">{t('cardValidFor', { months: codeDetails.valid_for })}</Text>
          ) : isCardExpired(codeDetails.expires_at) ? (
            <Text className="expiryDate">{t('cardExpired')}</Text>
          ) : (
            <Text className="expiryDate">{t('cardValidUntil', { date: displayDate(codeDetails.expires_at) })}</Text>
          )
        ) : null}
      </BodyWrapper>
      <ButtonsWrapper />
    </Container>
  );
};

export default Main;

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Container = styled(ColumnFlex)`
  width: 100%;
  height: 100%;
`;

const BodyWrapper = styled.div`
  margin-top: 40px;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;
