import { priceType } from 'constants/index';
import dayjs from 'dayjs';

export const formatCardNumber = (number: string): string => {
  let formattedText: string = number.split(' ').join('');
  if (!!formattedText) {
    formattedText = formattedText.match(new RegExp('.{1,4}', 'g'))?.join(' ') || '';
  }
  return formattedText;
};

export const isNumeric = (val: string): boolean => {
  return /^-?\d+$/.test(val);
};

export const removeSpaces = (value: string): string => value.replace(/\s/g, '');

export const displayDate = (date: any) => {
  return dayjs(date).format('DD.M.YYYY');
};

export const isCardExpired = (expires_at: string | undefined): boolean => {
  return !!(expires_at && dayjs(expires_at).diff(dayjs(dayjs().format('YYYY-MM-DD') + ' 00:00:00')) < 0);
};

export const returnPrice = (expires_at: string | undefined, priceData: priceType) => {
  return expires_at && isCardExpired(expires_at) ? priceData?.renewal_after_expiry : priceData?.renewal_before_expiry;
};

export const returnErrorMessageContent = (data: any): any => {
  if (!!data) {
    return Object.keys(data).map(val => {
      if (typeof val === 'string') return data[val] + `\n`;
      else return data[val][0] + `\n`;
    });
  } else return '';
};

export const returnSessionStorageValues = () => {
  const userToken = sessionStorage.getItem('Auth') || '';
  const AdminToken = sessionStorage.getItem('AdminAuth') || '';
  const isMuseumSelected = sessionStorage.getItem('MuseumSelected');
  const token = userToken || AdminToken;
  return { userToken, AdminToken, isMuseumSelected, token };
};
