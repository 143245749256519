import { useEffect, useMemo, useState } from 'react';
import RenderTable from 'UiKit/Table';

const IntervalDates = ({ data, name, dateName }: { data: any; name: string; dateName: string }) => {
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      let newData: any[] = [];
      data.forEach((row: any) => {
        let allVisits = row.visits_100 + row.visits_normal + row.visits_0 + row.visits_free;
        newData.push({ ...row, allVisits });
      });
      setRows(newData);
    }
  }, [data]);

  useMemo(() => {
    setColumns([
      {
        label: dateName,
        key: 'date',
      },
      {
        label: 'allVisits',
        key: 'allVisits',
      },
      {
        label: 'firstVisit100',
        key: 'visits_100',
      },
      {
        label: 'normalVisits',
        key: 'visits_normal',
      },
      {
        label: 'overVisits',
        key: 'visits_0',
      },
      {
        label: 'firstVisit0',
        key: 'visits_free',
      },
      {
        label: 'newCards',
        key: 'new_cards',
      },
      {
        label: 'renewalsValid',
        key: 'valid_cards_renewals',
      },
      {
        label: 'renewalsExpired',
        key: 'expired_cards_renewals',
      },
    ]);
  }, [dateName]);

  return <RenderTable label={name} data={rows} columns={columns} />;
};

export default IntervalDates;
