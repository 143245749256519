import { useEffect, useMemo, useState } from 'react';
import RenderTable from 'UiKit/Table';

const Invoice = ({ data }: any) => {
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      let newData: any[] = [];
      Object.keys(data).forEach((key: string) => {
        newData.push({ name: key, value: `${data[key]} kpl` });
      });
      setRows(newData);
    }
  }, [data]);

  useMemo(() => {
    setColumns([
      {
        label: 'area',
        key: 'name',
      },
      {
        label: 'pcs',
        key: 'value',
      },
    ]);
  }, []);

  return <RenderTable label={'postal'} data={rows} columns={columns} />;
};

export default Invoice;
