import { useEffect, useMemo, useState } from 'react';
import RenderTable from 'UiKit/Table';

const Today = ({ data }: any) => {
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const { visits, ...rest } = data;
      let allVisits =
        visits &&
        Object.values(visits).reduce((accumulator: number, value: any) => {
          return accumulator + value;
        }, 0);
      setRows([{ ...rest, ...visits, allVisits }]);
    }
  }, [data]);

  useMemo(() => {
    setColumns([
      {
        label: 'firstVisit100',
        key: 'visits_first',
      },
      {
        label: 'allVisits',
        key: 'allVisits',
      },
      {
        label: 'normalVisits',
        key: 'visits_normal',
      },
      {
        label: 'overVisits',
        key: 'visits_over',
      },
      {
        label: 'firstVisit0',
        key: 'visits_free',
      },
      {
        label: 'newCards',
        key: 'new_cards',
      },
      {
        label: 'renewalsValid',
        key: 'valid_cards_renewals',
      },
      {
        label: 'renewalsExpired',
        key: 'expired_cards_renewals',
      },
    ]);
  }, []);
  return <RenderTable label="today" data={rows} columns={columns} />;
};

export default Today;
