import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectCodeIsFetching, selectCodes, deleteCodes, selectCodeDetails, deleteCodeDetails } from 'store/code';
import { getCodeDetails, getCodes } from 'store/code/asyncActions';
import { Colors, FontSize } from 'constants/index';
import { formatCardNumber, isNumeric, removeSpaces } from 'utils/functions';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { selectMuseumData } from 'store/museum';
import Text from 'UiKit/Text';
import { Code } from 'api/main';

const CodesList = () => {
  const [t] = useTranslation();
  const codesData = useAppSelector(selectCodes);
  const museumData = useAppSelector(selectMuseumData);
  const loading = useAppSelector(selectCodeIsFetching);
  const codesDetails = useAppSelector(selectCodeDetails);
  const [codes, setCodes] = useState<any[]>([]);
  const [query, setQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(0);
  const [value, setValue] = useState<string>('');
  const [user, setUser] = useState<string>('');

  const returnUser = (user: any) => {
    return user ? `${user.firstname} ${user.lastname}` : '';
  };
  useEffect(() => {
    if (codesData) {
      let newCodes = codesData?.data.map((item: any) => `${item.code}/${returnUser(item.user)}`);
      setLastPage(codesData.meta.last_page);
      setCurrentPage(codesData.meta.current_page);
      if (codesData.meta.current_page !== codesData.meta.last_page) {
        newCodes.push('pagination');
      }
      setCodes(newCodes);
    } else setCodes([]);
  }, [codesData]);

  useEffect(() => {
    if (codesDetails) {
      (document.getElementById('codelist-input') as HTMLInputElement).value = formatCardNumber(
        codesDetails.code.toString()
      );
      setValue(codesDetails.code.toString());
    }
  }, [codesDetails]);

  useEffect(() => {
    if (value) {
      let codeOption = codesData?.data.find((item: Code) => item.code === parseInt(value));
      let user = codeOption?.user;
      setUser(`${returnUser(user)}`);
    } else {
      setUser('');
    }
  }, [value, codesData]);

  const dispatch = useAppDispatch();
  const debouncedChanged = (value: string) => {
    dispatch(getCodes({ query: removeSpaces(value) }));
    setQuery(removeSpaces(value));
  };

  const onChange = debounce(debouncedChanged, 300);

  const handlePagination = (page: number) => {
    dispatch(deleteCodes());
    dispatch(getCodes({ query, page }));
  };

  const handleChange = (code: string) => {
    dispatch(getCodeDetails(parseInt(code)));
  };

  return (
    <div>
      <AutoCompleteContainer
        id="codesAutoComplete"
        loading={loading}
        options={codes}
        disabled={!museumData}
        value={value}
        filterOptions={options => (options.length ? options : ['noOptions'])}
        freeSolo
        onChange={(props, option: any) => {
          let val = '';
          if (option) {
            let newOption = option.split('/');
            setValue(option);
            val = `${formatCardNumber(newOption[0])}`;
            handleChange(option.split('/')[0]);
          } else {
            setValue('');
            dispatch(deleteCodes());
            dispatch(deleteCodeDetails());
          }
          (document.getElementById('codelist-input') as HTMLInputElement).value = val;
        }}
        renderOption={(props, option: any) => {
          return option === 'noOptions' ? (
            <li className="noOptions" key={'noOptions'}>
              {t('noOptions')}
            </li>
          ) : option !== 'pagination' ? (
            <li {...props} key={option}>
              {formatCardNumber(option.split('/')[0])} {option.split('/')[1]}
            </li>
          ) : (
            <li className="dropdown-pagination" key={'pagination'}>
              {currentPage !== 1 && (
                <p
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlePagination(currentPage - 1);
                  }}
                >
                  {t('prev')}
                </p>
              )}
              {currentPage !== lastPage && (
                <p
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlePagination(currentPage + 1);
                  }}
                >
                  {t('next')}
                </p>
              )}
            </li>
          );
        }}
        renderInput={(params: any) => {
          let {
            inputProps: { value, ...restInput },
            ...restParams
          } = params;
          return (
            <TextField
              {...restParams}
              onChange={e => {
                const value = e.target.value;
                if (isNumeric(removeSpaces(value))) {
                  e.target.value = formatCardNumber(value);
                  value.length >= 3 && onChange(`${value}1`);
                } else {
                  e.target.value = formatCardNumber(value.replace(/\D/g, ''));
                }
                if (!value.length) {
                  e.target.value = '';
                  dispatch(deleteCodes());
                  dispatch(deleteCodeDetails());
                }
              }}
              placeholder={t('enterCode')}
              inputProps={{ ...restInput, maxLength: 14, id: 'codelist-input' }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color={'primary'} size={20} />
                    ) : (
                      <SearchIcon className="searchIcon" color="primary" />
                    )}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
      />
      {user && <Text fontSize={FontSize.EIGHTEEN}>{user}</Text>}
    </div>
  );
};

export default CodesList;

const AutoCompleteContainer = styled(Autocomplete)`
  .MuiTextField-root {
    padding-top: 5px;
  }
  .MuiOutlinedInput-root {
    padding-right: 30px !important;
  }
  background-color: ${props => (props.disabled ? `${Colors.LIGHT_GREY} !important` : `${Colors.WHITE} !important`)};
  width: 100% !important;
  height: 72px !important;
  display: inline-block !important;
  border-radius: 10px !important;
  border: none !important;
  box-sizing: border-box !important;
  outline: none !important;
  box-shadow: inset 0px 1px 2px ${Colors.TRANSPARENT_TOLOPEA} !important;
  input {
    color: ${Colors.TOLOPEA} !important;
    line-height: 140% !important;
    font-size: ${FontSize.EIGHTEEN} !important;
    -webkit-text-fill-color: ${Colors.TOLOPEA} !important;
  }
  fieldset {
    display: none;
  }
`;
