import { FontSize, Colors, FontWeight, FontFamily, ElementTypes, BtnWrapper } from 'constants/index';
import Text from 'UiKit/Text';

interface Props {
  closeModals: () => void;
  onSubmit: () => void;
  title: string;
  children: any;
  btnLoding?: boolean;
  btnDisabled?: boolean;
  btnText?: string;
  showCancelBtn?: boolean;
}
const ModalWrapper = ({
  closeModals,
  onSubmit,
  title,
  children,
  btnDisabled,
  btnLoding,
  btnText = 'continue',
  showCancelBtn = true,
}: Props) => {
  return (
    <>
      <Text textAlign={'center'} fontWeight={FontWeight.EIGHT} width="fit-content" fontFamily={FontFamily.EXTRABOLD}>
        {title}
      </Text>
      {children}
      <div>
        <BtnWrapper
          text={btnText}
          fontSize={FontSize.SIXTEEN}
          bgColor={Colors.FRENCH_ROSE}
          onClick={onSubmit}
          isLoading={btnLoding}
          disabled={btnDisabled}
        />
        {showCancelBtn && (
          <BtnWrapper
            buttonType={ElementTypes.TERTIARY}
            text={'cancel'}
            fontSize={FontSize.SIXTEEN}
            onClick={closeModals}
          />
        )}
      </div>
    </>
  );
};
export default ModalWrapper;
