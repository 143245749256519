import { useCallback, useEffect, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import Pagination from '@mui/material/Pagination';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectRatingDeleteIsFetching, selectRatingsData, selectRatingsFetching } from 'store/ratings';
import { Colors } from 'constants/index';
import Button from 'UiKit/Button';
import RenderTable from 'UiKit/Table';
import { deleteRating, getRatings } from 'store/ratings/asyncActions';
import Text from 'UiKit/Text';
import { useTranslation } from 'react-i18next';
import { selectMuseumData } from 'store/museum';

const RatingsList = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const ratingsData = useAppSelector(selectRatingsData);
  const museumData = useAppSelector(selectMuseumData);
  const deleteIsFetching = useAppSelector(selectRatingDeleteIsFetching);
  const dataIsFetching = useAppSelector(selectRatingsFetching);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [meta, setMeta] = useState<{
    current_page: number;
    last_page: number;
    total: number;
  }>({ current_page: 0, last_page: 0, total: 0 });

  useEffect(() => {
    if (ratingsData) {
      const { total, last_page, current_page } = ratingsData.meta;
      setRows(ratingsData.data);
      setMeta({ total, last_page, current_page });
    } else {
      setRows([]);
    }
  }, [ratingsData]);

  const handleDelete = useCallback(
    (id: number, visible: boolean) => {
      dispatch(deleteRating({ ratingId: id, visibility: visible, page: meta.current_page }));
    },
    [dispatch, meta.current_page]
  );
  const handlePagination = (page: number) => {
    dispatch(getRatings({ museumId: museumData.data.id, page }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useMemo(() => {
    setColumns([
      {
        label: 'rating',
        key: 'rating',
        style: { textAlign: 'center', width: '5%' },
        render: (text: string) => `${text}/5`,
      },
      {
        label: 'comment',
        key: 'comment',
        style: { width: '60%' },
      },
      {
        label: 'visitDate',
        key: 'visit_date',
        style: { width: '25%' },
      },
      {
        label: 'actions',
        key: 'id',
        style: { width: '10%' },
        render: (id: number, field: any) => (
          <Button
            size="small"
            containerStyle={{ width: '100%', background: Colors.DARKER_RED, border: 'none' }}
            text={'delete'}
            leftIcon={<DeleteIcon htmlColor={Colors.WHITE} />}
            onClick={() => {
              setDeleteId(id);
              handleDelete(id, !field.visible);
            }}
            isLoading={id === deleteId && deleteIsFetching}
          />
        ),
      },
    ]);
  }, [handleDelete, deleteIsFetching, deleteId]);

  return rows.length ? (
    <Container>
      <RenderTable data={rows} columns={columns} style={{ width: '100%' }} isLoading={dataIsFetching} />
      <Pagination
        count={meta.last_page}
        onChange={(e, value: number) => handlePagination(value)}
        showFirstButton
        showLastButton
        variant="outlined"
        color="primary"
      />
    </Container>
  ) : (
    <Text className="noRating">{t('noRatings')}</Text>
  );
};

export default RatingsList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  align-items: center;
  .MuiPaginationItem-root {
    color: ${Colors.TOLOPEA};
    background: rgba(147, 172, 236, 0.5);
  }
  .MuiPaginationItem-ellipsis {
    background: none !important;
  }
`;
