import { createGlobalStyle } from 'styled-components';
import RightGroteskRegular from './RightGroteskWideRegular.ttf';
import RightGroteskBold from './RightGroteskWideBold.ttf';
import RightGroteskEXTRABold from './RightGroteskSpatialDark.ttf';
import RightGroteskLight from './RightGroteskSpatialLight.ttf';
import MuseoSansRounded from './exljbris-MuseoSansRounded-700.otf';
import { FontWeight, Colors } from 'constants/index';

const GlobalStyle = createGlobalStyle`
    @font-face {
    font-family: 'RightGrotesk-Regular';
    src: url('${RightGroteskRegular}') format('opentype');
    }

    @font-face {
    font-family: 'RightGrotesk-bold';
    src: url('${RightGroteskBold}') format('opentype');
    font-weight: 700;
    }
    
    @font-face {
    font-family: 'RightGrotesk-extra-bold';
    src: url('${RightGroteskEXTRABold}') format('opentype');
    font-weight: 800
    }

    @font-face {
      font-family: 'RightGrotesk-light';
      src: url('${RightGroteskLight}') format('opentype');
      }
      @font-face {
        font-family: 'MuseoSansRounded';
        src: url('${MuseoSansRounded}');
      }
    html, body { 
        height: 100%; 
        width: 100%;
      }

      .MuiAutocomplete-listbox {
        height: fit-content !important;
        max-height: none !important;
        background: white !important;
        li {
          color: ${Colors.TOLOPEA} !important;
          border-bottom: 1px solid ${Colors.GREY_CHATEAU};
          &:last-child {
            border-bottom: none !important;
          }
        }
      }
      .MuiAutocomplete-noOptions, .MuiAutocomplete-loading{
        background: white !important;
        color: ${Colors.TOLOPEA} !important;
      }
      .dropdown-pagination{
        display: flex;
        justify-content: center;
        gap: 10px;
        padding: 10px 0px;
        p{
          font-weight: ${FontWeight.EIGHT};
          cursor: pointer;
        }
      }
      .MuiCircularProgress-svg {
        color: ${Colors.FRENCH_ROSE} !important;
      }
      .MuiAutocomplete-clearIndicator, .MuiSelect-iconOutlined{
        color: black !important;
      }
      .searchIcon{
        color: black !important;
      }
      .MuiAutocomplete-popupIndicator{
        display: none !important;
      }
      @media print {
        .no-print, 
        .no-print * {
          display: none !important;
        }
      }
      .hidden { 
        display: none !important;
      }
      .noOptions {
        pointer-event: none;
        padding: 5px;
        font-style: italic;
      }
`;
export default GlobalStyle;
