import { useEffect, useMemo, useState } from 'react';
import RenderTable from 'UiKit/Table';

const Invoice = ({ data }: any) => {
  const [rows, setRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      let newData: any[] = [];
      data.forEach((row: any) => {
        let first = `${row.visits_100}pcs / ${row.visits_100_euro}€`;
        let normal = `${row.visits_normal}pcs / ${row.visits_normal_euro}€`;
        let over = `${row.visits_0}pcs / ${row.visits_0_euro}€`;
        let free = `${row.visits_free}pcs / ${row.visits_free_euro}€`;
        let provisio = `${row.provisio}pcs / ${row.provisio_euro}€`;

        newData.push({ name: row.month_name, free, normal, over, provisio, first });
      });
      setRows(newData);
    }
  }, [data]);

  useMemo(() => {
    setColumns([
      {
        label: 'month',
        key: 'name',
      },
      {
        label: 'firstVisit100',
        key: 'first',
      },
      {
        label: 'normalVisits',
        key: 'normal',
      },
      {
        label: 'overVisits',
        key: 'over',
      },
      {
        label: 'firstVisit0',
        key: 'free',
      },
      {
        label: 'provisio',
        key: 'provisio',
      },
    ]);
  }, []);

  return <RenderTable label={''} data={rows} columns={columns} />;
};

export default Invoice;
