import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { WebService } from 'api/main';
import { AppLoginForm } from 'constants/index';
import { resetMuseumData } from 'store/museum';
import { getMuseums } from 'store/museum/asyncActions';

export const loginRequestAsync: AsyncThunk<any, any, any> = createAsyncThunk(
  'auth/loginRequest',
  async (body: AppLoginForm, { rejectWithValue, dispatch }) => {
    try {
      const response = await WebService.webAdminAuthenticate(body);
      sessionStorage.setItem('AdminAuth', response.token || '');
      sessionStorage.setItem('Auth', response.token || '');
      dispatch(getMuseums());
      return response;
    } catch (error: any) {
      return rejectWithValue(error.body);
    }
  }
);

export const logoutRequestAsync = createAsyncThunk('auth/logoutRequest', async (_, { rejectWithValue, dispatch }) => {
  try {
    sessionStorage.clear();
    dispatch(resetMuseumData());
    return null;
  } catch (error: any) {
    return rejectWithValue(error.body);
  }
});
