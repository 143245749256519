import './i18n';
import React, { FC, memo, ReactElement } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { store } from './store';
import { NotificationProvider, StyleProvider } from './providers';
import Meta from './utils/widgets/meta';

type AppConfigsProp = {
  children: ReactElement | ReactElement[];
};

const AppConfigs: FC<AppConfigsProp> = ({ children }) => {
  return (
    <StoreProvider store={store}>
      <Meta>
        <title>Event Calendar</title>
        <meta name="description" content="Parent component" />
      </Meta>
      <StyleProvider>
        <NotificationProvider>{children}</NotificationProvider>
      </StyleProvider>
    </StoreProvider>
  );
};

export default memo(AppConfigs);
