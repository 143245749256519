import { JSXElementConstructor, ReactElement } from 'react';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import Close from 'assets/icons/close.svg';
import Box from '@mui/material/Box';

interface ModalParams {
  isVisible: boolean;
  className?: string;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  setVisible: () => void;
}

const ModalComponent = ({ isVisible, children, setVisible }: ModalParams) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    minWidth: 150,
    maxWidth: 400,
    bgcolor: 'white',
    border: 'none',
    borderRadius: '10px',
    p: 4,
  };

  return (
    <Modal open={isVisible} onClose={setVisible}>
      <BoxStyled sx={style}>
        <CloseContainer src={Close} alt="alose" onClick={setVisible} />
        {children}
      </BoxStyled>
    </Modal>
  );
};

export default ModalComponent;

const CloseContainer = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
