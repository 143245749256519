import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Colors } from 'constants/index';
import Input from 'UiKit/Input';
import { verfivyPinCode } from 'store/museum/asyncActions';
import { deletePincodeErrorMessage, selectPinCodeErrorMessage, selectPinCodeIsVerifiying } from 'store/museum';
import { useSnackbar } from 'notistack';
import ModalWrapper from './modalWrapper';

const PinCodeVerificationModal = ({ closeModals, onSubmit }: { closeModals: () => void; onSubmit: () => void }) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState<string>('');
  const isVerifiying = useAppSelector(selectPinCodeIsVerifiying);
  const errorMessage = useAppSelector(selectPinCodeErrorMessage);

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      dispatch(deletePincodeErrorMessage());
    }
  }, [errorMessage, dispatch, enqueueSnackbar]);

  const padPinCode = (n: any): any => {
    n = n + '';
    return n.length >= 4 ? n : new Array(4 - n.length + 1).join('0') + n;
  };

  const handleSubmit = () => {
    dispatch(verfivyPinCode(padPinCode(value))).then((res: any) => {
      if (!res.error) {
        onSubmit();
      }
    });
  };

  return (
    <ModalWrapper
      title={t('enterPinCode')}
      btnLoding={isVerifiying}
      btnDisabled={!value || isVerifiying}
      closeModals={closeModals}
      onSubmit={handleSubmit}
    >
      <Input
        id="pinCode"
        defaultValue={value}
        onChange={e => {
          setValue(e.target.value);
        }}
        backgroundColor={Colors.ALICE_BLUE}
      />
    </ModalWrapper>
  );
};
export default PinCodeVerificationModal;
