import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { WebService } from 'api/main';

export const getRatings = createAsyncThunk(
  'web/ratings',
  async ({ museumId, page = 1 }: { museumId: number; page?: number }, { rejectWithValue, dispatch }) => {
    try {
      const response = await WebService.webRatingListByMuseums(museumId, 10, page);
      await dispatch(getAverageRating(museumId));
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAverageRating = createAsyncThunk(
  'web/ratings/average',
  async (museumId: number, { rejectWithValue }) => {
    try {
      const response = await WebService.webMuseumGetAverageRating(museumId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRating: AsyncThunk<any, { ratingId: number; visibility: boolean; page: number }, {}> =
  createAsyncThunk(
    'web/rating/delete',
    async (
      body: {
        ratingId: number;
        visibility: boolean;
        page: number;
      },
      { rejectWithValue, dispatch, getState }
    ) => {
      try {
        const state: any = getState();
        const museumId = state.museum.data.data.id;
        const response = await WebService.webRatingUpdateVisibility(body.ratingId, { visibility: body.visibility });
        await dispatch(getRatings({ museumId, page: body.page }));
        return response;
      } catch (error: any) {
        return rejectWithValue(error.body);
      }
    }
  );

export const downloadRatings = createAsyncThunk(
  'web/ratings/download',
  async (museumId: number, { rejectWithValue }) => {
    try {
      const response = await WebService.webRatingDownload(museumId);
      window.open(response.download_link);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
