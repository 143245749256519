export enum FontWeight {
  NORMAL = 'normal',
  BOLD = 'bold',
  EIGHT = 800,
  SEVEN = 700,
  FOUR = 400,
}

export enum FontSize {
  EIGHT = '8px',
  NINE = '9px',
  TEN = '10px',
  TWELVE = '12px',
  THIRTEEN = '13px',
  FOURTEEN = '14px',
  FIFTEEN = '15px',
  SIXTEEN = '16px',
  SEVENTEEN = '17px',
  EIGHTEEN = '18px',
  TWENTY = '20px',
  TWENTY_ONE = '21px',
  TWENTY_FOUR = '24px',
  TWENTY_FIVE = '25px',
  TWENTY_EIGHT = '28px',
  THIRTY_TWO = '32px',
  THIRTY_SIX = '36px',
  FORTY = '40px',
  FORTY_FOUR = '44px',
  FIFTY = '50px',
  SIXTY = '60px',
  SEVENTY = '70px',
}

export enum FontFamily {
  NORMAL = 'RightGrotesk-Regular',
  BOLD = 'RightGrotesk-bold',
  EXTRABOLD = 'RightGrotesk-extra-bold',
  LIGHT = 'RightGrotesk-light',
}
