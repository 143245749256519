/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Code } from '../models/Code';
import type { CodeSheetResponse } from '../models/CodeSheetResponse';
import type { CompanyOrder } from '../models/CompanyOrder';
import type { CompanyOrderIdentifier } from '../models/CompanyOrderIdentifier';
import type { Coupon } from '../models/Coupon';
import type { EventCalendarStatistics } from '../models/EventCalendarStatistics';
import type { MinimalMuseum } from '../models/MinimalMuseum';
import type { Offer } from '../models/Offer';
import type { PaymentForms } from '../models/PaymentForms';
import type { Rating } from '../models/Rating';
import type { RatingList } from '../models/RatingList';
import type { RegisterNewsLetterReceiver } from '../models/RegisterNewsLetterReceiver';
import type { UserPaymentCard } from '../models/UserPaymentCard';
import type { UserTransaction } from '../models/UserTransaction';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WebService {

    /**
     * Create new user
     * create new user
     * @param requestBody create new user
     * @param lang records language
     * @returns any Created
     * @throws ApiError
     */
    public static webUserCreate(
        requestBody: {
            code: number;
            firstname: string;
            lastname: string;
            birth_year?: number;
            address: string;
            city: string;
            email: string;
            zip_code: string;
            newsletter?: boolean;
            contact_language?: string;
            coupon_code?: string;
            password: string;
            plasticCard: boolean;
            phone?: string;
        },
        lang?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/user/create',
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Update user info
     * update user info
     * @param requestBody update user info
     * @returns any Ok
     * @throws ApiError
     */
    public static webUserUpdateInfo(
        requestBody: {
            date_of_birth?: number;
            address: string;
            city: string;
            email: string;
            zip_code: string;
            phone?: string;
            subscribed: boolean;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/web/user/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Subscribe user for standing order
     * subscribe user for standing order
     * @param requestBody Subscribe user for standing order
     * @returns any Ok
     * @throws ApiError
     */
    public static webUserSettingsCreate(
        requestBody: {
            user_id: number;
            auto_renewal: boolean;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/user/settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized - Token expired`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Update user subscription for standing order
     * Update user subscription for standing order
     * @param requestBody Update user subscription for standing order
     * @returns any Accepted
     * @throws ApiError
     */
    public static webUserSettingsUpdate(
        requestBody: {
            user_id: number;
            auto_renewal: boolean;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/web/user/settings/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized - Token expired`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Deactivate user auto_renewal/subscription
     * Deactivate user auto_renewal/subscription
     * @returns any Accepted
     * @throws ApiError
     */
    public static webUserSettingUnsubscribe(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/web/user/settings/unsubscribe',
            errors: {
                401: `Unauthorized - Token expired`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Subscribe to newsletter
     * Subscribe newsletter
     * @returns any Accepted
     * @throws ApiError
     */
    public static webUserNewsletterSubscribe(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/web/user/newsletter/subscribe',
            errors: {
                401: `Unauthorized - Token expired`,
            },
        });
    }

    /**
     * Get list of transactions for user
     * Get list of transactions for user
     * @param orderBy Records ordering direction
     * @param pageSize Records count per page
     * @param page The number of page of the results
     * @returns any Success
     * @throws ApiError
     */
    public static webUserTransactions(
        orderBy?: string,
        pageSize?: number,
        page?: number,
    ): CancelablePromise<{
        data?: Array<UserTransaction>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/user/transactions',
            query: {
                'order_by': orderBy,
                'page_size': pageSize,
                'page': page,
            },
            errors: {
                401: `Unauthorized - Token expired`,
                404: `Not found`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Get valid payment card for user
     * Get valid payment card for user
     * @returns any Success
     * @throws ApiError
     */
    public static webUserPaymentCards(): CancelablePromise<{
        data?: Array<UserPaymentCard>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/user/payments/cards',
            errors: {
                401: `Unauthorized - Token expired`,
                404: `Not found`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * user login
     * user login
     * @param requestBody Login
     * @param lang records language
     * @returns any Success
     * @throws ApiError
     */
    public static webUserLogin(
        requestBody: {
            code: number;
            email?: string;
            password: string;
        },
        lang?: string,
    ): CancelablePromise<{
        token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/user/login',
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                422: `Unprocessable Entity`,
                429: `Too many failed attempts. Please try again later.`,
            },
        });
    }

    /**
     * Get User info
     * Get User info
     * @returns any Success
     * @throws ApiError
     */
    public static webUserInfo(): CancelablePromise<{
        id?: number;
        created_at?: string;
        updated_at?: string;
        firstname?: string;
        lastname?: string;
        date_of_birth?: number;
        email?: string;
        phone?: string;
        address?: string;
        city?: string;
        zip_code?: string;
        country?: string;
        photo_updated_at?: string;
        photo?: string;
        role?: string;
        subscribed?: boolean;
        user_option?: string;
        reviews_count?: number;
        visits_count?: number;
        codes?: Array<{
            code?: number;
            user_id?: number;
            printing_museum_id?: number;
            first_museum_id?: number;
            first_visit?: string;
            expires_at?: string;
            valid_for?: number;
            codesheet?: number;
            created_at?: string;
            updated_at?: string;
            registered_at?: string;
            status?: string;
            deleted_at?: string;
            company_order_id?: number;
            visits_count?: number;
            sold_at?: string;
            seller_museum_id?: number;
            code_sheet_id?: number;
        }>;
        readonly valid_for_renewal?: boolean;
        readonly has_standing_order?: boolean;
        standing_order_date?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/user/info',
            errors: {
                401: `Unauthorized - Token expired`,
            },
        });
    }

    /**
     * admin authenticate
     * admin authenticate
     * @param requestBody Login
     * @returns any Success
     * @throws ApiError
     */
    public static webAdminAuthenticate(
        requestBody: {
            email: string;
            password: string;
        },
    ): CancelablePromise<{
        token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/user/admin/authenticate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden - Access denied`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * user registration via gift4you
     * user registration via gift4you
     * @param requestBody Register
     * @returns any Success - User is created/A new Code has generated and mailed to the User's email address
     * @throws ApiError
     */
    public static webAdminGift4YouRegister(
        requestBody: {
            firstname: string;
            lastname: string;
            birth_year: number;
            address: string;
            city: string;
            email: string;
            zip_code: string;
            newsletter: boolean;
            contact_language?: string;
            coupon_code?: string;
            password: string;
            plasticCard: boolean;
            gift4you_code: string;
            phone_number?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/user/gift4you/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Failure - Gift4You code can not be redeemed, possible codes:  0=NOT_FOUND, 2=ALREADY_REDEEMED, 3=PRODUCT_VOIDED`,
                422: `Failure - User is not created due to validation errors`,
            },
        });
    }

    /**
     * check gift4you code
     * check gift4you code
     * @param requestBody Check
     * @returns any Success - Check the response body
     * @throws ApiError
     */
    public static webAdminGift4YouCheck(
        requestBody: {
            gift4you_code: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/user/gift4you/check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Failure - Code is not valid to be used in registration API`,
            },
        });
    }

    /**
     * Renew code
     * renew code
     * @param requestBody Renew code request parameters
     * @returns any Code renewed
     * @throws ApiError
     */
    public static webCodeRenew(
        requestBody: {
            code?: number;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden - Access denied`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Generate new codes sheet
     * Generate new codes sheet
     * @returns any Success
     * @throws ApiError
     */
    public static webCodeGenerateCodeSheet(): CancelablePromise<{
        data?: Array<CodeSheetResponse>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/code/code-sheet',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Logout
     * Logout
     * @returns void
     * @throws ApiError
     */
    public static webAuthLogout(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/auth/logout',
            errors: {
                400: `Failed to revoke access token`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Logout
     * Logout
     * @returns void
     * @throws ApiError
     */
    public static webAuthSecretaryLogout(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/auth/secretary/logout',
            errors: {
                400: `Failed to revoke access token`,
                401: `Unauthorized - Token expired`,
            },
        });
    }

    /**
     * Get login page for dashboard statistics
     * Get login page for dashboard statistics
     * @returns void
     * @throws ApiError
     */
    public static webAhtSecretary(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/auth/secretary',
        });
    }

    /**
     * Authenticate user to view dashboard statistics
     * Authenticate user to view dashboard statistics
     * @param requestBody Login
     * @returns any Ok
     * @throws ApiError
     */
    public static webAhtSecretaryLogin(
        requestBody: {
            email: string;
            password: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/auth/secretary/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Mark code as sold
     * Mark code as sold
     * @param requestBody Mark code as sold
     * @returns any Ok
     * @throws ApiError
     */
    public static webCodeMarkSold(
        requestBody: {
            code: number;
            museum_id: number;
            source: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/code/mark-sold',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized - Token expired`,
                403: `Forbidden - Access denied`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Cancel code sale
     * @param requestBody Cancel code sale
     * @returns any Accepted
     * @throws ApiError
     */
    public static webCodeCancelSale(
        requestBody: {
            code: number;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/code/cancel-sale',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `if code has visits OR is registered OR is merged OR status != sold OR is renewed`,
                422: `Code must exist`,
            },
        });
    }

    /**
     * get a list of codes
     * get a list of codes
     * @param query search text
     * @param orderBy Records ordering direction
     * @param pageSize Records count per page
     * @param page The number of page of the results
     * @returns any Success
     * @throws ApiError
     */
    public static webCodeSearch(
        query?: string,
        orderBy?: string,
        pageSize?: number,
        page?: number,
    ): CancelablePromise<{
        data?: Array<Code>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/code/search',
            query: {
                'query': query,
                'order_by': orderBy,
                'page_size': pageSize,
                'page': page,
            },
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * view GroupMessage of a User
     * view GroupMessage of a User
     * @param query group message id
     * @param token user token
     * @returns string Success
     * @throws ApiError
     */
    public static webGroupMessageView(
        query: number,
        token: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/group-message',
            query: {
                'query': query,
                'token': token,
            },
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * register NewsLetterReceiver
     * register NewsLetterReceiver
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static webNewsLetterReceiverRegister(
        requestBody: RegisterNewsLetterReceiver,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/news-letter-receiver',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Merge 2 codes
     * @param requestBody Renew or extend validity period for original code by combining with gift code, If museum_id not present in request body user's lastname will be used
     * @param lang records language
     * @returns any Code data received
     * @throws ApiError
     */
    public static webCodeMerge(
        requestBody: {
            /**
             * If not present in request body, user's lastname will be used
             */
            museum_id?: number;
            source?: string;
            code: number;
            gift_code: number;
        },
        lang?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/code/merge',
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `1. 'exception' (belongs to excepted museums list, cannot be used for merging) 2. 'used' (registered or has visits, cannot be used for merging) 3. 'failed' (failed to update original code/ send email) 4. 'merged' (gift code is already merged) 5. 'unsold' (card was not marked as sold)`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Get/Check code detail
     * @param code code of museum
     * @param lang records language
     * @returns any Check Code resource
     * @throws ApiError
     */
    public static webCodeGet(
        code: number,
        lang?: string,
    ): CancelablePromise<{
        readonly code?: number;
        readonly registered?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/code/{code}',
            path: {
                'code': code,
            },
            query: {
                'lang': lang,
            },
            errors: {
                403: `Forbidden - Access denied`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Validate code
     * @param requestBody
     * @returns any Access code resource
     * @throws ApiError
     */
    public static webCodeValidate(
        requestBody: {
            code: number;
            museum_id: number;
        },
    ): CancelablePromise<{
        readonly code?: number;
        readonly is_registered?: boolean;
        readonly customer_name?: string;
        readonly sold_at?: string;
        readonly seller_museum_id?: number;
        readonly expires_at?: string;
        readonly days_left?: number;
        readonly merged_status?: string;
        readonly original_code?: string;
        readonly free?: string;
        readonly regular?: string;
        readonly auto_renew?: boolean;
        readonly to_be_renewed_at?: string;
        readonly free_visits_only?: boolean;
        readonly valid_for_renewal?: boolean;
        readonly can_be_sold?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/code/validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not found`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Create code
     * @returns any Access code resource
     * @throws ApiError
     */
    public static webCodeNew(): CancelablePromise<{
        readonly code?: number;
        readonly is_registered?: boolean;
        readonly customer_name?: string;
        readonly sold_at?: string;
        readonly seller_museum_id?: number;
        readonly expires_at?: string;
        readonly days_left?: number;
        readonly merged_status?: string;
        readonly original_code?: string;
        readonly free?: string;
        readonly regular?: string;
        readonly auto_renew?: boolean;
        readonly to_be_renewed_at?: string;
        readonly free_visits_only?: boolean;
        readonly valid_for_renewal?: boolean;
        readonly can_be_sold?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/code/new',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get code extended details
     * @param requestBody
     * @returns any Code extended resource
     * @throws ApiError
     */
    public static webCodeExtendedDetails(
        requestBody: {
            code: number;
        },
    ): CancelablePromise<{
        readonly code?: number;
        readonly printing_museum_id?: number;
        readonly user_id?: number;
        readonly first_museum_id?: number;
        readonly first_visit?: number;
        readonly expires_at?: string;
        readonly valid_for?: number;
        readonly valid_for_renewal?: boolean;
        readonly can_be_sold?: boolean;
        readonly codesheet?: number;
        readonly status?: string;
        readonly created_at?: string;
        readonly updated_at?: string;
        readonly registered_at?: string;
        readonly sold_at?: string;
        readonly visits_free?: number;
        readonly visits_regular?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/code/details',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * user newsletter subscription
     * user newsletter subscription
     * @param token user token
     * @returns any Success
     * @throws ApiError
     */
    public static userNewsletterSubscription(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/user/newsletter',
            query: {
                'token': token,
            },
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Mark visit for the code
     * @param requestBody Request for creating new visit
     * @returns any Visit has been marked for the code
     * @throws ApiError
     */
    public static webVisitCreate(
        requestBody: {
            code: number;
            info: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/visit',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Cannot create visit`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * get visit count
     * @returns any Visit count
     * @throws ApiError
     */
    public static webVisitCount(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/visit/count',
            errors: {
                401: `Unauthorized - Token expired`,
            },
        });
    }

    /**
     * Create rating
     * @param requestBody create rating
     * @returns Rating Success
     * @throws ApiError
     */
    public static webRatingCreate(
        requestBody: {
            visit_id?: number;
            museum_id?: number;
            rating?: number;
        },
    ): CancelablePromise<Rating> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/rating',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * List rating of a Museum
     * @param museumId id of museum
     * @param pageSize Records count per page
     * @param page The number of page of the results
     * @returns any Success
     * @throws ApiError
     */
    public static webRatingListByMuseums(
        museumId: number,
        pageSize?: number,
        page?: number,
    ): CancelablePromise<{
        data?: Array<RatingList>;
        links?: {
            first?: string;
            last?: string;
            prev?: string;
            next?: string;
        };
        meta?: {
            current_page?: number;
            from?: number;
            last_page?: number;
            path?: string;
            per_page?: number;
            to?: number;
            total?: number;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/rating/{museumId}',
            path: {
                'museumId': museumId,
            },
            query: {
                'page_size': pageSize,
                'page': page,
            },
        });
    }

    /**
     * Download rating of a Museum
     * @param museumId id of museum
     * @returns any Success
     * @throws ApiError
     */
    public static webRatingDownload(
        museumId: number,
    ): CancelablePromise<{
        download_link?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/rating/{museumId}/download',
            path: {
                'museumId': museumId,
            },
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * update rating
     * @param ratingId id of rating
     * @param requestBody update rating
     * @returns any Ok
     * @throws ApiError
     */
    public static webRatingUpdate(
        ratingId: number,
        requestBody: {
            rating: number;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/web/rating/{ratingId}',
            path: {
                'ratingId': ratingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * update rating comment
     * @param ratingId id of rating
     * @param requestBody update rating
     * @returns any Ok
     * @throws ApiError
     */
    public static webRatingUpdateComment(
        ratingId: number,
        requestBody: {
            rating: number;
            comment: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/web/rating/{ratingId}/comment',
            path: {
                'ratingId': ratingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * delete rating comment
     * @param ratingId id of rating
     * @param requestBody delete rating comment
     * @returns any Ok
     * @throws ApiError
     */
    public static webRatingDeleteComment(
        ratingId: number,
        requestBody: {
            note: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/web/rating/{ratingId}/comment',
            path: {
                'ratingId': ratingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * update rating visibility
     * @param ratingId id of rating
     * @param requestBody update rating visibility
     * @returns any Ok
     * @throws ApiError
     */
    public static webRatingUpdateVisibility(
        ratingId: number,
        requestBody: {
            visibility: boolean;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/web/rating/{ratingId}/visibility',
            path: {
                'ratingId': ratingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * update transaction
     * @param transactionId id of transaction
     * @param requestBody update transaction
     * @returns any Accepted
     * @throws ApiError
     */
    public static webTransactionUpdate(
        transactionId: number,
        requestBody: {
            status?: string;
            filing_code?: string;
            method?: string;
            customerInfo?: string;
            auto_renewal?: boolean;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/web/transaction/{transactionId}',
            path: {
                'transactionId': transactionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * user visits
     * user visits
     * @param orderBy Records ordering direction
     * @param pageSize Records count per page
     * @param page The number of page of the results
     * @returns any Success
     * @throws ApiError
     */
    public static userVisits(
        orderBy?: string,
        pageSize?: number,
        page?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/user/visits',
            query: {
                'order_by': orderBy,
                'page_size': pageSize,
                'page': page,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not found`,
            },
        });
    }

    /**
     * get receipt info
     * @param filingCode filing_code of transaction
     * @returns any Success
     * @throws ApiError
     */
    public static webTransactionReceiptInfo(
        filingCode: string,
    ): CancelablePromise<{
        code?: string;
        name?: string;
        date?: string;
        invoice_number?: number;
        phone?: string;
        street?: string;
        city?: string;
        zipcode?: string;
        price?: number;
        type?: string;
        buyer?: string;
        receivers?: string;
        total?: number;
        amount?: number;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/transaction/receipt-info',
            query: {
                'filing_code': filingCode,
            },
            errors: {
                403: `Forbidden - Transaction is not paid`,
                404: `Not found`,
            },
        });
    }

    /**
     * set user password with token sent with email
     * user password
     * @param requestBody Set password
     * @param lang records language
     * @returns any
     * @throws ApiError
     */
    public static webUserPassword(
        requestBody: {
            token: string;
            password: string;
            password_confirmation?: string;
        },
        lang?: string,
    ): CancelablePromise<{
        message?: string;
        token?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/user/password',
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden - Token expired`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Reset user password via email
     * user password reset
     * @param requestBody Reset password
     * @returns any Success
     * @throws ApiError
     */
    public static webUserPasswordReset(
        requestBody: {
            email: string;
            code: string;
            lang?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/user/password/reset',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Failure - If Email was used in multiple User records`,
                422: `Unprocessable Entity`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * get museum
     * @returns any Success
     * @throws ApiError
     */
    public static webMuseumGet(): CancelablePromise<{
        readonly id?: number;
        readonly event_calendar_id?: number;
        readonly name?: string;
        address?: {
            readonly street?: string;
            readonly zip_code?: string;
            readonly city?: string;
        };
        readonly email?: string;
        readonly contact?: string;
        pin_code?: string;
        free_museum?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/museum',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * get museum minimal list
     * @returns MinimalMuseum Museum minimal resource
     * @throws ApiError
     */
    public static webMuseumGetMinimal(): CancelablePromise<Array<MinimalMuseum>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/museum/minimal',
        });
    }

    /**
     * get exhibition list
     * @param page The number of page of the results
     * @returns any exhibition list
     * @throws ApiError
     */
    public static webMuseumExhibitionList(
        page?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/museum/exhibition',
            query: {
                'page': page,
            },
        });
    }

    /**
     * get museum
     * @param id id of the of record
     * @returns any Museum resource
     * @throws ApiError
     */
    public static webMuseumGetInfo(
        id: number,
    ): CancelablePromise<{
        readonly id?: number;
        readonly event_calendar_id?: number;
        readonly name?: string;
        address?: {
            readonly street?: string;
            readonly zip_code?: string;
            readonly city?: string;
        };
        readonly email?: string;
        readonly contact?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/museum/{id}/info',
            path: {
                'id': id,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * get museum average rating
     * @param id id of the of record
     * @returns any Museum average rating
     * @throws ApiError
     */
    public static webMuseumGetAverageRating(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/museum/{id}/average-rating',
            path: {
                'id': id,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * verify museum pin code
     * @param pinCode pin of museum
     * @returns any Museum average rating
     * @throws ApiError
     */
    public static webMuseumVerifyPinCode(
        pinCode: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/museum/{pinCode}/verify',
            path: {
                'pinCode': pinCode,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * get museum qr
     * @returns any Museum QR
     * @throws ApiError
     */
    public static webMuseumGetQr(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/museum/qr',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * update museum qr
     * @returns any Museum QR
     * @throws ApiError
     */
    public static webMuseumUpdateQr(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/web/museum/qr',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * get museums tokens
     * @returns any tokens list
     * @throws ApiError
     */
    public static webMuseumsGetTokens(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/museum/token',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden - Access denied`,
            },
        });
    }

    /**
     * get announcement for customer portal
     * get announcement for customer portal
     * @returns any Success
     * @throws ApiError
     */
    public static webAnnouncementOffers(): CancelablePromise<{
        items?: Offer;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/announcement/offers',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * get news for customer portal
     * get news for customer portal
     * @returns any Success
     * @throws ApiError
     */
    public static webAnnouncementNews(): CancelablePromise<{
        items?: Offer;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/announcement/news',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * validate coupon code
     * check if coupon code is valid
     * @param requestBody Check Coupon
     * @param lang
     * @returns any Success
     * @throws ApiError
     */
    public static webCouponCheck(
        requestBody: {
            coupon_code: string;
            /**
             * Source of request, can be any value of (personal, registration, customer_portal)
             */
            from: string;
        },
        lang?: string,
    ): CancelablePromise<{
        items?: Coupon;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/coupon/check',
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                429: `Too many failed attempts. Please try again later.`,
            },
        });
    }

    /**
     * Get event calendar statistics
     * Get statistics for specific museum in event calendar
     * @returns any Success
     * @throws ApiError
     */
    public static webStatisticsEventCalendar(): CancelablePromise<{
        items?: EventCalendarStatistics;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/statistics/event_calendar',
        });
    }

    /**
     * Get dashboard statistics
     * Get dashboard statistics
     * @returns any Success response with view containing tables of statistics
     * @throws ApiError
     */
    public static webUserStatisticsDashboard(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/statistics/dashboard',
        });
    }

    /**
     * Create new company Order
     * Create new company Order
     * @param requestBody Create new company order
     * @returns any Success
     * @throws ApiError
     */
    public static webCompanyOrder(
        requestBody: {
            amount: number;
            company: string;
            /**
             * send company order as paper cards or digitally (email and/or sms). value can be either letter or digital
             */
            delivery_type: string;
            contact_person: string;
            contact_email: string;
            contact_phone: string;
            /**
             * required only if delivery_type = letter
             */
            recipient: string;
            /**
             * required only if delivery_type = letter
             */
            address: string;
            /**
             * required only if delivery_type = letter
             */
            zip: string;
            /**
             * required only if delivery_type = letter
             */
            city: string;
            company_on_card?: string;
            business_code?: string;
            partner_museum_id?: string;
            partner_contact?: string;
            invoicing_company?: string;
            invoicing_address?: string;
            invoicing_zip?: string;
            invoicing_city?: string;
            invoice_reference?: string;
            einvoice_address?: string;
            einvoice_operator?: string;
            einvoice_broker_id?: string;
        },
    ): CancelablePromise<{
        items?: CompanyOrder;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/companyOrder',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Get Company Order by identifier
     * Get Company Order by identifier
     * @param identifier Identifier for company order
     * @returns any Success
     * @throws ApiError
     */
    public static webCompanyOrderPdf(
        identifier: string,
    ): CancelablePromise<{
        items?: CompanyOrderIdentifier;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/companyOrder/pdf/{identifier}',
            path: {
                'identifier': identifier,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
            },
        });
    }

    /**
     * Generate Payment Forms
     * Generate Payment Forms
     * @param requestBody Generate forms for different payment providers
     * @param lang records language
     * @returns any Success
     * @throws ApiError
     */
    public static webPaymentForms(
        requestBody: {
            /**
             * type of payment, should be any value form (add_card, personal, renew, or gift)
             */
            type: string;
            /**
             * source of payment, should be either web_shop, or customer_portal
             */
            source: string;
            /**
             * required if type any of (personal, renew, or gift)
             */
            amount: number;
            /**
             * should be any value from (production, staging, or local) based on projects environment
             */
            environment: string;
            contact_lang: string;
            auto_renewal: boolean;
            plastic_card: boolean;
            /**
             * required only if type is personal
             */
            customer_first_name?: string;
            /**
             * required only if type is personal
             */
            customer_last_name?: string;
            /**
             * required only if type is personal
             */
            customer_email?: string;
            /**
             * required only if type is personal
             */
            customer_phone?: string;
            /**
             * required only if type is personal
             */
            customer_address?: string;
            /**
             * required only if type is personal
             */
            customer_zipcode?: string;
            /**
             * required only if type is personal
             */
            customer_city?: string;
            /**
             * required only if type is personal
             */
            customer_date_of_birth?: string;
            /**
             * required only if type is personal
             */
            newsletter?: string;
            /**
             * required only if type is personal
             */
            password?: string;
            /**
             * required only if type is personal
             */
            password_confirmation?: string;
            /**
             * required only if payment type is gift
             */
            gift_type?: string;
            /**
             * required only if payment type is gift
             */
            gift_buyer_name?: string;
            /**
             * required only if payment type is gift
             */
            gift_buyer_email?: string;
            /**
             * not required but can be sent if added by customer and payment type is gift
             */
            gift_buyer_phone?: string;
            /**
             * required only if payment type is gift
             */
            gift_greetings_message?: string;
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_first_name?: string;
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_last_name?: string;
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_address?: string;
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_zip_code?: string;
            /**
             * required only if payment type is gift and gift type is letter
             */
            gift_receiver_city?: string;
            /**
             * not required but can be sent if added by customer and payment type is gift and gift type is letter
             */
            gift_receiver_organisation?: string;
            /**
             * required only if payment type is gift and gift type is digital
             */
            gift_send_at?: string;
            /**
             * required only if payment type is gift and gift type is digital, should contain array of arrays, each array is for gift receiver, and each should include receiver_name
             */
            gift_info?: Array<Array<{
                receiver_name?: string;
            }>>;
            /**
             * required only if payment type is renew or add_card
             */
            user_id?: number;
            /**
             * required only if payment type is renew
             */
            renewal_type?: string;
            coupon_code?: string;
        },
        lang?: string,
    ): CancelablePromise<{
        items?: PaymentForms;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/payment/forms',
            query: {
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Handle response from payment service providers
     * Handle response from payment service providers
     * @param requestBody Handle response from payment service providers
     * @returns any Successful Payment Response
     * @throws ApiError
     */
    public static webPaymentSuccess(
        requestBody: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/payment/success',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Handle Paytrail Notifications
     * Handle Paytrail Notifications
     * @param requestBody Handle notification about possible issues related to paytrail payment
     * @returns any Success
     * @throws ApiError
     */
    public static webPaymentNotify(
        requestBody: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/payment/paytrail/notify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Add new payment card
     * Add new payment card
     * @param requestBody Add new payment card
     * @returns any Created
     * @throws ApiError
     */
    public static webPaymentCard(
        requestBody: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/payment/card',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get price for new card
     * Get price for new card
     * @returns any New card price resource - success response
     * @throws ApiError
     */
    public static webPriceCard(): CancelablePromise<{
        data?: Array<{
            readonly cents?: number;
            readonly type?: string;
            readonly started_at?: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/price/card',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get prices for web shop
     * Get prices for web shop
     * @returns any Prices for web shop resource - success response
     * @throws ApiError
     */
    public static webPriceCardWebShop(): CancelablePromise<{
        data?: Array<{
            readonly card?: number;
            readonly digital_gift?: number;
            readonly letter_gift?: number;
            readonly renewal_after_expiry?: number;
            readonly renewal_before_expiry?: number;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/price/card/web_shop',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get prices for event calendar
     * Get prices for event calendar
     * @returns any Prices for event calendar resource - success response
     * @throws ApiError
     */
    public static webPriceCardEventCalendar(): CancelablePromise<{
        data?: Array<{
            readonly card?: number;
            readonly renewal_after_expiry?: number;
            readonly renewal_before_expiry?: number;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/price/card/event_calendar',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get renewals prices for customer portal
     * Get renewals prices for customer portal
     * @returns any Prices for renewals in customer portal resource - success response
     * @throws ApiError
     */
    public static webPriceRenewals(): CancelablePromise<{
        data?: Array<{
            readonly renewal_after_expiry?: number;
            readonly renewal_before_expiry?: number;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/price/renewals',
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * get customer portal elements list
     * @returns any Customer portal elements list
     * @throws ApiError
     */
    public static webCustomerPortalElementsList(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/customer-portal/element',
        });
    }

    /**
     * get customer portal translation
     * @param pageName
     * @param language
     * @returns any Success
     * @throws ApiError
     */
    public static webCustomerPortalTranslation(
        pageName: string,
        language: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/translation/{page_name}/{language}',
            path: {
                'page_name': pageName,
                'language': language,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * News List
     * News List
     * @param orderBy Records ordering direction
     * @param pageSize Records count per page
     * @param lang records language
     * @param page The number of page of the results
     * @returns any News list for Customer Portal
     * @throws ApiError
     */
    public static latestNewsList(
        orderBy?: string,
        pageSize?: number,
        lang?: string,
        page?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/news',
            query: {
                'order_by': orderBy,
                'page_size': pageSize,
                'lang': lang,
                'page': page,
            },
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Get header
     * Get remote header
     * @param lang file language
     * @returns string Header html
     * @throws ApiError
     */
    public static getRemoteHeader(
        lang: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/web-pages/header',
            query: {
                'lang': lang,
            },
            errors: {
                404: `Header not found`,
            },
        });
    }

    /**
     * Get footer
     * Get remote footer
     * @param lang file language
     * @returns string Footer html
     * @throws ApiError
     */
    public static getRemoteFooter(
        lang: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web/web-pages/footer',
            query: {
                'lang': lang,
            },
            errors: {
                404: `Footer not found`,
            },
        });
    }

    /**
     * Add request info to logs
     * Add request info to logs
     * @param requestBody request parameters
     * @returns any Ok
     * @throws ApiError
     */
    public static requestLogger(
        requestBody: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web/util/logger',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}