import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'constants/index';
import { RootState } from '../index';
import { getRatings, getAverageRating, deleteRating, downloadRatings } from './asyncActions';

export interface RatingsState {
  is_fetching: boolean;
  data: any | undefined;
  status: string | null;
  averageRating: any;
  delete_is_fetching: boolean;
  deleteSuccessMessage: string;
  deleteErrorMessage: string;
  download_is_fetching: boolean;
}

const initialState: RatingsState = {
  is_fetching: false,
  data: null,
  status: null,
  averageRating: null,
  delete_is_fetching: false,
  deleteSuccessMessage: '',
  deleteErrorMessage: '',
  download_is_fetching: false,
};

export const ratingsSlice = createSlice({
  name: 'ratings',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    resetRatings: state => {
      state.data = [];
      state.status = null;
    },
    resetAverage: state => {
      state.averageRating = null;
    },
    resetDeleteSuccessMessage: state => {
      state.deleteSuccessMessage = '';
    },
    resetDeleteErrorMessage: state => {
      state.deleteErrorMessage = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getRatings.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.data = action.payload;
        state.status = Status.SUCCEEDED;
      })
      .addCase(getAverageRating.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.averageRating = action.payload;
      })
      .addCase(deleteRating.fulfilled, state => {
        state.delete_is_fetching = false;
        state.deleteSuccessMessage = 'deletedSuccessfully';
      })
      .addCase(deleteRating.pending, state => {
        state.delete_is_fetching = true;
      })
      .addCase(deleteRating.rejected, (state, action: any) => {
        state.delete_is_fetching = false;
        state.deleteErrorMessage = action.payload?.message || action.payload?.error.message;
      })
      .addCase(downloadRatings.fulfilled, state => {
        state.download_is_fetching = false;
      })
      .addCase(downloadRatings.pending, state => {
        state.download_is_fetching = true;
      })
      .addCase(downloadRatings.rejected, (state, action: any) => {
        state.download_is_fetching = false;
        state.deleteErrorMessage = action.payload?.message || action.payload?.error.message;
      })
      .addMatcher(isAnyOf(getRatings.pending, getAverageRating.pending), state => {
        state.is_fetching = true;
      })
      .addMatcher(isAnyOf(getRatings.rejected, getAverageRating.rejected), state => {
        state.is_fetching = false;
        state.status = Status.FAILED;
      });
  },
});

export const { setStatus, resetRatings, resetDeleteSuccessMessage, resetDeleteErrorMessage, resetAverage } =
  ratingsSlice.actions;

export const selectRatingsData = (state: RootState): RatingsState['data'] => state.ratings.data;
export const selectRatingsFetching = (state: RootState): RatingsState['is_fetching'] => state.ratings.is_fetching;
export const selectRatingsStatus = (state: RootState): RatingsState['status'] => state.ratings.status;
export const selectAverageRatings = (state: RootState): RatingsState['averageRating'] => state.ratings.averageRating;
export const selectRatingDeleteIsFetching = (state: RootState): RatingsState['delete_is_fetching'] =>
  state.ratings.delete_is_fetching;
export const selectRatingDeleteSuccessMessage = (state: RootState): RatingsState['deleteSuccessMessage'] =>
  state.ratings.deleteSuccessMessage;
export const selectRatingDeleteErrorMessage = (state: RootState): RatingsState['deleteErrorMessage'] =>
  state.ratings.deleteErrorMessage;
export const selectRatingDownloadsFetching = (state: RootState): RatingsState['download_is_fetching'] =>
  state.ratings.download_is_fetching;
export default ratingsSlice.reducer;
