import HeaderGuard from './headerGuard';
import Header from './header';

const HeaderContainer = () => {
  return (
    <HeaderGuard>
      <Header />
    </HeaderGuard>
  );
};

export default HeaderContainer;
