import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCodePrice, selectNewCodeIsFetching } from 'store/code';
import { createNewCode } from 'store/code/asyncActions';
import Text from 'UiKit/Text';
import ModalWrapper from './modalWrapper';

const NewCardConfirmModal = ({ closeModals }: { closeModals: () => void }) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const priceData = useAppSelector(selectCodePrice);
  const isFetching = useAppSelector(selectNewCodeIsFetching);

  const handleSubmit = () => {
    dispatch(createNewCode());
  };

  return (
    <ModalWrapper
      title={t('newCardConfirmTitle')}
      closeModals={closeModals}
      onSubmit={handleSubmit}
      btnLoding={isFetching}
    >
      <Text textAlign={'center'}>{t('NewCardConfirmMessage', { price: priceData.card / 100 })}</Text>
    </ModalWrapper>
  );
};
export default NewCardConfirmModal;
