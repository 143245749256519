import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from 'UiKit/Button';
import Text from 'UiKit/Text';
// import { ElementTypes } from 'constants/index';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  deleteMessages,
  selectCodeDetails,
  selectCodeDetailsErrorMessage,
  selectCodeDetailsSuccessMessage,
} from 'store/code';
import { markVisit, renewCode } from 'store/code/asyncActions';
import ModalComponent from 'UiKit/Modal';
import CombineCardsModal from './mergeCards';
import MarkSoldModal from './markSold';
import NewCardModal from './newCard';
import { isCardExpired } from 'utils/functions';
import { selectMuseumData } from 'store/museum';
import PinCodeVerificationModal from './pincodeVerification';
import NewCardConfirmModal from './newCardConfirm';

const ButtonsWrapper = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const codesData = useAppSelector(selectCodeDetails);
  const museumData = useAppSelector(selectMuseumData);
  const errorMessage = useAppSelector(selectCodeDetailsErrorMessage);
  const successMessage = useAppSelector(selectCodeDetailsSuccessMessage);
  const [showModal, setShowModal] = useState<string>('');

  useEffect(() => {
    if (successMessage) {
      if (successMessage === 'cardMergedExtended') {
        enqueueSnackbar(t(successMessage, { extendedBy: codesData.extendedBy }), {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(t(successMessage), {
          variant: 'success',
        });
      }
      closeModals();
      if (successMessage === 'newCodeCreated') setShowModal('newCard');
      dispatch(deleteMessages());
    }
  }, [successMessage, dispatch, enqueueSnackbar, t, codesData?.extendedBy]);

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      dispatch(deleteMessages());
    }
  }, [errorMessage, dispatch, enqueueSnackbar]);

  const handleRenew = () => {
    closeModals();
    dispatch(renewCode(codesData.code));
  };

  const handleVisit = (info: string) => {
    dispatch(markVisit({ code: codesData.code, info }));
  };

  const closeModals = () => {
    setShowModal('');
  };

  const returnModalContent = () => {
    switch (showModal) {
      case 'newCard':
        return <NewCardModal closeModals={closeModals} />;
      case 'sold':
        return <MarkSoldModal closeModals={closeModals} />;
      case 'merge':
        return <CombineCardsModal closeModals={closeModals} />;
      case 'newCardConfirm':
        return <NewCardConfirmModal closeModals={closeModals} />;
      default:
        return <PinCodeVerificationModal closeModals={closeModals} onSubmit={handleRenew} />;
    }
  };

  return (
    <Container>
      {museumData ? (
        !codesData ? (
          <Button text={'createNewCard'} onClick={() => setShowModal('newCardConfirm')}></Button>
        ) : codesData.status === 'merged' ? (
          <Text className="expiryDate">{t('codeCombined')}</Text>
        ) : codesData.can_be_sold ? (
          <Button text={'markAsSold'} onClick={() => setShowModal('sold')}></Button>
        ) : (
          <>
            <Row>
              {!codesData.visits_regular &&
                !codesData.free_visits_only &&
                !museumData.data.free_museum &&
                !isCardExpired(codesData.expires_at) && (
                  <Button text={'markVisit'} onClick={() => handleVisit('regular')}></Button>
                )}
              {codesData.valid_for_renewal && codesData.expires_at && codesData.is_registered && (
                <Button text={'renew'} onClick={() => setShowModal('pincode')}></Button>
              )}
            </Row>
            {codesData.user_id && <Button text={'mergeCards'} onClick={() => setShowModal('merge')}></Button>}
            {!codesData.visits_free && !isCardExpired(codesData.expires_at) && (
              <Button text={'markFreeVisits'} onClick={() => handleVisit('free')}></Button>
            )}
            {/* <Button buttonType={ElementTypes.TERTIARY} text={'cancelFunctions'} onClick={() => {}}></Button> */}
          </>
        )
      ) : null}
      <ModalComponent isVisible={showModal ? true : false} setVisible={closeModals}>
        {returnModalContent()}
      </ModalComponent>
    </Container>
  );
};

export default ButtonsWrapper;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 25%;
  gap: 5px;

  > button {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  button {
    width: 100%;
  }
`;
