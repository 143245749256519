import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppSelector } from 'store/hooks';
import { selectQrData } from 'store/qr';
import GooglePlay from 'assets/icons/google-play-badge.png';
import AppleStore from 'assets/icons/app_store_black_badge.png';
import MuseumCard from 'assets/icons/museokortti.png';
import { selectMuseumData } from 'store/museum';

const QrCode = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const QRcodeData = useAppSelector(selectQrData);
  const museumData = useAppSelector(selectMuseumData);

  useEffect(() => {
    if (!museumData) {
      enqueueSnackbar(t('selectAMuseumFirst'), {
        variant: 'error',
      });
      navigate('/');
    }
  }, [enqueueSnackbar, navigate, museumData, t]);

  return (
    <Container id="mainContainer">
      <br />
      <img src={MuseumCard} id="cardImage" alt="museum" />
      <h1 id="visitText">Merkitse museokäynti</h1>
      <div id="container">
        <div>
          <div id="registerVisitText">
            Registrera museibesöket
            <br />
            Mark your museum visit
          </div>
        </div>
        <div id="qrCodeContainer">
          <br className="brStyle" />
          <img src={QRcodeData?.url} id="qrCodeImage" alt="qr" />
          <br className="brStyle" />
        </div>
        <div id="appLink">Museot.fi/sovellus</div>
        <table style={{ width: '50mm', alignSelf: 'center' }}>
          <tr style={{ alignSelf: 'center' }}>
            <td align="left">
              <img src={GooglePlay} id="playStoreImage" alt="google" />
            </td>
            <td align="right">
              <img src={AppleStore} id="appStoreImage" alt="apple" />
            </td>
          </tr>
        </table>
        <div id="bottomText">{QRcodeData?.museumName}</div>
      </div>
    </Container>
  );
};

export default QrCode;

const Container = styled.div`
  background-color: #ed008d !important;
  -webkit-print-color-adjust: exact !important;
  font-family: 'MuseoSansRounded' !important;
  background-color: white !important;
  margin: 0 auto;

  background-color: #ed008d !important;
  height: 148mm !important;
  width: 105mm !important;
  margin: 0 auto;
  -webkit-print-color-adjust: exact !important;

  #cardImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 15%;
    width: 33%;
  }
  #bottomText {
    color: white;
    text-align: center;
    font-size: 15px;
  }
  #playStoreImage {
    width: 95%;
    padding-right: 2px;
  }
  #appStoreImage {
    width: 100%;
    padding-left: 2px;
  }
  #appLink {
    color: yellow;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bolder;
    font-size: 20px;
  }
  #qrCodeImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    height: 75%;
    width: 75%;
  }
  #qrCodeContainer {
    background-color: white;
    border-radius: 10px;
    width: 50%;
    height: 60%;
    margin: 0 auto;
  }
  #visitText {
    color: white;
    text-align: center;
    font-weight: 800;
    font-size: 32px;
  }
  #container {
    width: 95%;
    margin: 0 auto;
  }
  #registerVisitText {
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  table {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
  .brStyle {
    line-height: 2px;
  }
`;
