import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'constants/index';
import { RootState } from '../index';
import { getStatistics } from './asyncActions';

export interface StatisticsState {
  is_fetching: boolean;
  data: any;
  status: string | null;
}

const initialState: StatisticsState = {
  is_fetching: false,
  data: null,
  status: null,
};

export const statisticsSlice = createSlice({
  name: 'Statistics',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    resetStatistics: state => {
      state.data = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getStatistics.pending, state => {
        state.is_fetching = true;
      })
      .addCase(getStatistics.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.data = action.payload;
      })
      .addCase(getStatistics.rejected, state => {
        state.is_fetching = false;
        state.status = Status.FAILED;
      });
  },
});

export const { setStatus, resetStatistics } = statisticsSlice.actions;

export const selectStatisticsData = (state: RootState): StatisticsState['data'] => state.statistics.data;
export const selectStatisticssFetching = (state: RootState): StatisticsState['is_fetching'] =>
  state.statistics.is_fetching;
export const selectStatisticsStatus = (state: RootState): StatisticsState['status'] => state.statistics.status;

export default statisticsSlice.reducer;
