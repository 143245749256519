import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { getMuseumInfo, getMuseums, verfivyPinCode } from './asyncActions';
import { Status } from 'constants/index';

export interface MuseumState {
  is_fetching: boolean;
  data: any;
  status: string | null;
  allMuseums: any[];
  pincode_is_verifying: boolean;
  pincodeErrorMessage: string;
}

const initialState: MuseumState = {
  is_fetching: false,
  data: null,
  status: null,
  allMuseums: [],
  pincode_is_verifying: false,
  pincodeErrorMessage: '',
};

export const mesuemSlice = createSlice({
  name: 'museum',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    resetMuseumData: state => {
      state.data = null;
    },
    deletePincodeErrorMessage: state => {
      state.pincodeErrorMessage = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMuseumInfo.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.data = action.payload;
      })
      .addCase(getMuseumInfo.rejected, state => {
        state.is_fetching = false;
        state.status = Status.FAILED;
      })
      .addCase(getMuseums.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.allMuseums = action.payload;
      })
      .addCase(getMuseums.rejected, state => {
        state.is_fetching = false;
      })
      .addCase(verfivyPinCode.fulfilled, state => {
        state.pincode_is_verifying = false;
      })
      .addCase(verfivyPinCode.pending, state => {
        state.pincode_is_verifying = true;
      })
      .addCase(verfivyPinCode.rejected, (state, action: any) => {
        state.pincode_is_verifying = false;
        state.pincodeErrorMessage = action.payload?.message || action.payload?.error.message;
      })
      .addMatcher(isAnyOf(getMuseumInfo.pending, getMuseums.pending), state => {
        state.is_fetching = true;
      });
  },
});

export const { setStatus, resetMuseumData, deletePincodeErrorMessage } = mesuemSlice.actions;

export const selectMuseumData = (state: RootState): MuseumState['data'] => state.museum.data;
export const selectMuseumIsFetching = (state: RootState): MuseumState['is_fetching'] => state.museum.is_fetching;
export const selectMuseumStatus = (state: RootState): MuseumState['status'] => state.museum.status;
export const selectAllMuseums = (state: RootState): MuseumState['allMuseums'] => state.museum.allMuseums;
export const selectPinCodeIsVerifiying = (state: RootState): MuseumState['pincode_is_verifying'] =>
  state.museum.pincode_is_verifying;
export const selectPinCodeErrorMessage = (state: RootState): MuseumState['pincodeErrorMessage'] =>
  state.museum.pincodeErrorMessage;
export default mesuemSlice.reducer;
