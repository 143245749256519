import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'UiKit/Input';
import { AppLoginForm, BtnWrapper, Colors, FontFamily, FontSize, FontWeight, Status } from 'constants/index';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loginRequestAsync } from 'store/auth/asyncActions';
import { deleteErrorMessage, selectLoginErrorMessage, selectLoginIsFetching, selectLoginStatus } from 'store/auth';

const loginSchema = Yup.object().shape({
  password: Yup.string().min(8).max(50).required(),
  email: Yup.string().email().required(),
});

const Login = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errorMessage = useAppSelector(selectLoginErrorMessage);
  const isFetching = useAppSelector(selectLoginIsFetching);
  const loginStatus = useAppSelector(selectLoginStatus);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loginStatus === Status.SUCCEEDED) navigate('/');
  }, [loginStatus, navigate]);

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      dispatch(deleteErrorMessage());
    }
  }, [errorMessage, dispatch, enqueueSnackbar]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = handleSubmit(async (data: AppLoginForm) => {
    dispatch(loginRequestAsync(data));
  });

  return (
    <Container>
      <div>
        <InputWrapper id={'username'} placeholder={t('username')} {...register('email')} control={control} />
        {errors.email && <Error>{errors.email?.message}</Error>}
      </div>
      <div>
        <InputWrapper
          id={'password'}
          type="password"
          placeholder={t('password')}
          {...register('password')}
          control={control}
        />
        {errors.password && <Error>{errors.password?.message}</Error>}
      </div>
      <BtnWrapper onClick={onSubmit} text={'login'} isLoading={isFetching} />
    </Container>
  );
};
export default Login;

const Container = styled.div`
  width: 60%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  > div {
    width: 40%;
  }
`;

const InputWrapper = styled(Input)`
  width: 100%;
`;

const Error = styled.div`
  color: ${Colors.RED};
  font-weight: ${FontWeight.SEVEN};
  font-family: ${FontFamily.NORMAL};
  font-size: ${FontSize.TWELVE};
`;
