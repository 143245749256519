import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { getMuseumInfo, getMuseums } from 'store/museum/asyncActions';
import { Status } from 'constants/index';
import { setStatus } from 'store/auth';
import Loading from 'UiKit/Loading';
import { returnSessionStorageValues } from 'utils/functions';

export const RouterGuard = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const museumToken = useMemo(() => urlParams.get('token'), [urlParams]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const { userToken, AdminToken, isMuseumSelected, token } = returnSessionStorageValues();
    sessionStorage.setItem('Auth', userToken);
    const handleMuseumCalls = async () => {
      if (AdminToken) {
        sessionStorage.setItem('Auth', AdminToken);
        dispatch(setStatus(Status.SUCCEEDED));
        await dispatch(getMuseums());
      }
      if ((userToken && !AdminToken) || (AdminToken && isMuseumSelected)) {
        sessionStorage.setItem('Auth', userToken);
        await dispatch(getMuseumInfo());
      }
      setLoading(false);
    };

    if (museumToken) {
      sessionStorage.setItem('Auth', museumToken);
      dispatch(getMuseumInfo());
    } else if (token) {
      handleMuseumCalls();
    } else {
      setLoading(false);
    }
  }, [dispatch, museumToken]);

  return loading ? <Loading /> : children;
};

export default RouterGuard;
