import { FunctionComponent, CSSProperties, ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import Text from '../Text';
import { Colors, FontWeight, FontFamily, ElementTypes } from 'constants/index';
import { useTranslation } from 'react-i18next';

function textColor(type: ElementTypes): Colors {
  switch (type) {
    case ElementTypes.PRIMARY:
    case ElementTypes.SECONDARY:
      return Colors.WHITE;
    case ElementTypes.TERTIARY:
      return Colors.FRENCH_ROSE;
    default:
      return Colors.WHITE;
  }
}

function buttonBGColor(type: ElementTypes | undefined): Colors {
  switch (type) {
    case ElementTypes.PRIMARY:
      return Colors.FRENCH_ROSE;
    case ElementTypes.SECONDARY:
      return Colors.ROYAL_BLUE;
    case ElementTypes.TERTIARY:
      return Colors.TRANSPARENT;
    default:
      return Colors.FRENCH_ROSE;
  }
}

interface LeftIconStyledProps {
  leftIconStyle?: CSSProperties;
}

interface ButtonContainerStyledProps {
  buttonType?: ElementTypes;
  size?: 'normal' | 'small';
  bgColor?: Colors;
}

interface Props extends ButtonContainerStyledProps, LeftIconStyledProps {
  text: string;
  onClick: () => void;
  leftIcon?: ReactNode;
  containerClassName?: string;
  fontClassName?: string;
  containerStyle?: CSSProperties;
  className?: string;
  fontSize?: string;
  fontWeight?: FontWeight;
  fontFamily?: FontFamily;
  disabled?: boolean;
  id?: string;
  isLoading?: boolean;
}

const Button: FunctionComponent<Props> = ({
  text,
  onClick,
  size = 'normal',
  bgColor,
  containerClassName,
  className,
  containerStyle,
  fontSize,
  leftIcon,
  fontWeight = FontWeight.SEVEN,
  fontFamily = FontFamily.BOLD,
  fontClassName,
  disabled,
  leftIconStyle,
  id,
  isLoading,
  buttonType = ElementTypes.PRIMARY,
}: Props) => {
  const [t] = useTranslation();

  return (
    <ButtonContainerStyled
      className={containerClassName || className}
      style={{ ...containerStyle }}
      onClick={onClick}
      buttonType={buttonType}
      size={size}
      bgColor={bgColor}
      disabled={disabled || isLoading}
      id={id || text}
    >
      {!isLoading && leftIcon && leftIcon}

      {isLoading ? (
        <CircularProgress color={'primary'} size={20} />
      ) : (
        <Text
          className={fontClassName}
          textAlign="center"
          fontWeight={fontWeight}
          fontFamily={fontFamily}
          color={textColor(buttonType)}
          fontSize={fontSize}
        >
          {t(text)}
        </Text>
      )}
    </ButtonContainerStyled>
  );
};

export default Button;

const ButtonContainerStyled = styled.button<ButtonContainerStyledProps>`
  background-color: ${({ buttonType, bgColor, disabled }): Colors =>
    disabled ? Colors.GREY_CHATEAU : bgColor ? bgColor : buttonBGColor(buttonType)};
  justify-content: center;
  align-items: center;
  height: ${({ size }): string => (size === 'normal' ? '50px' : '40px')};
  border-radius: ${({ size }): string => (size === 'normal' ? '30px' : '24px')};
  border-width: ${({ bgColor }): string => (bgColor ? '0' : '1px')};
  border-style: solid;
  border-color: ${({ buttonType, disabled }): Colors =>
    disabled ? Colors.GREY_CHATEAU : buttonType === ElementTypes.SECONDARY ? Colors.ROYAL_BLUE : Colors.FRENCH_ROSE};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding-left: 0px;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  .MuiCircularProgress-svg {
    color: ${Colors.ALICE_BLUE} !important;
  }
`;
