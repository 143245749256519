import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
  return (
    <LoadingContainer>
      <CircularProgress color={'primary'} size={60} />
    </LoadingContainer>
  );
};

export default Loading;

const LoadingContainer = styled.div`
  padding-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
