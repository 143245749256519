import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { Status } from 'constants/index';
import { getQrCode } from './asyncActions';
import { QrModal } from './modal/qrModal';

export interface QrState {
  is_fetching: boolean;
  data: QrModal | null;
  status: string | null;
}

const initialState: QrState = {
  is_fetching: false,
  data: null,
  status: null,
};

export const qrSlice = createSlice({
  name: 'qr',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getQrCode.pending, state => {
        state.is_fetching = true;
      })
      .addCase(getQrCode.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.data = action.payload;
      })
      .addCase(getQrCode.rejected, state => {
        state.is_fetching = false;
        state.status = Status.FAILED;
      });
  },
});

export const { setStatus } = qrSlice.actions;

export const selectQrData = (state: RootState): QrState['data'] => state.qr.data;
export const selectQrFetching = (state: RootState): QrState['is_fetching'] => state.statistics.is_fetching;
export const selectQrStatus = (state: RootState): QrState['status'] => state.qr.status;

export default qrSlice.reducer;
