import { createAsyncThunk } from '@reduxjs/toolkit';
import { WebService } from 'api/main';
import { getCodePrice } from 'store/code/asyncActions';
import { getQrCode } from 'store/qr/asyncActions';

export const getMuseumInfo = createAsyncThunk('web/museum', async (_, { rejectWithValue, dispatch }) => {
  try {
    const response = await WebService.webMuseumGet();
    dispatch(getQrCode());
    dispatch(getCodePrice());
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMuseums = createAsyncThunk('web/museum/list', async (_, { rejectWithValue }) => {
  try {
    const response = await WebService.webMuseumsGetTokens();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const verfivyPinCode = createAsyncThunk(
  'web/museum/pincode/verify',
  async (pincode: number, { rejectWithValue }) => {
    try {
      const response = await WebService.webMuseumVerifyPinCode(pincode);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.body);
    }
  }
);
