import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Colors, FontSize } from 'constants/index';
import { Control, Controller } from 'react-hook-form';

interface IInputParams {
  control?: Control<any>;
  label?: string;
  name?: string;
  defaultValue?: string;
  backgroundColor?: Colors;
  onChange?: (e: { target: { value: string } }) => void;
  multiline?: boolean;
  className?: string;
  readOnly?: boolean;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  id?: string;
}

const Input: FunctionComponent<IInputParams> = ({
  defaultValue,
  backgroundColor = Colors.WHITE,
  onChange = e => {},
  readOnly = false,
  placeholder = '',
  className = '',
  control,
  type = 'text',
  name = '',
  maxLength,
  id,
}: IInputParams) => {
  const config = {
    maxLength: maxLength,
    readOnly: readOnly,
    backgroundcolor: backgroundColor,
    placeholder: placeholder,
    role: 'input-field',
    defaultValue: defaultValue,
    type: type,
    disabled: readOnly,
  };

  return (
    <Container className={`vertical justify-content-start input ${className}`} backgroundColor={backgroundColor}>
      <div className={'item justify-content-start flex-none'}>
        <StyledLabel>{name}</StyledLabel>
      </div>
      <div className={'item'}>
        {control ? (
          <Controller
            render={({ field }) => {
              return <input id={id} className="inputField" {...field} {...config} />;
            }}
            name={name}
            control={control}
          />
        ) : (
          <input id={id} className="inputField" onChange={onChange} {...config} />
        )}
      </div>
    </Container>
  );
};

export default Input;

const Container = styled.div`
  height: fit-content;
  .item {
    flex: none;
  }
  .inputField {
    background-color: ${(props: IInputParams) => props.backgroundColor};
    width: 100%;
    height: 72px;
    padding: 12px 20px;
    display: inline-block;
    border-radius: 10px;
    border: none;
    box-sizing: border-box;
    color: ${Colors.TOLOPEA};
    line-height: 140%;
    font-size: ${FontSize.TWENTY} !important;
    outline: none;
    box-shadow: inset 0px 1px 2px ${Colors.TRANSPARENT_TOLOPEA};
    padding-left: 25px;
  }
`;

const StyledLabel = styled.label`
  color: #2c253d;
  font-size: 18px;
  line-height: 24px;
  height: 24px;
  font-weight: 700;
  margin-bottom: 7px;
  @media (max-width: 700px) {
    height: fit-content;
  }
`;
