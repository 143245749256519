import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { FontSize, FontWeight, FontFamily, Colors } from 'constants/index';

interface ITextStyleParams {
  color?: Colors;
  onClick?: () => void;
  fontSize?: FontSize | string;
  fontFamily?: string;
  fontWeight?: FontWeight;
  wordWrap?: string;
  whiteSpace?: string;
  textAlign?: 'center' | 'left' | 'right' | 'justify';
  children: ReactNode;
  width?: string;
  display?: string;
  height?: string;
  className?: string;
  id?: string;
}

const Text: FunctionComponent<ITextStyleParams> = ({
  color,
  fontSize = FontSize.FOURTEEN,
  fontFamily = FontFamily.NORMAL,
  onClick,
  width,
  height = 'fit-content',
  display = 'flex',
  fontWeight = FontWeight.NORMAL,
  textAlign = 'center',
  wordWrap = 'break-word',
  whiteSpace = 'normal',
  children,
  className = '',
  id,
}: ITextStyleParams) => {
  return (
    <StyledDiv
      onClick={onClick}
      className={className}
      color={color}
      width={width}
      height={height}
      display={display}
      fontSize={fontSize}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      textAlign={textAlign}
      wordWrap={wordWrap}
      whiteSpace={whiteSpace}
      id={id}
    >
      {children}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  color: ${(props: ITextStyleParams) => props.color};
  font-size: ${(props: ITextStyleParams) => props.fontSize};
  font-weight: ${(props: ITextStyleParams) => props.fontWeight};
  word-break: ${(props: ITextStyleParams) => props.wordWrap};
  white-space: ${(props: ITextStyleParams) => props.whiteSpace};
  width: ${(props: ITextStyleParams) => props.width};
  height: ${(props: ITextStyleParams) => props.height};
  justify-content: ${(props: ITextStyleParams) => props.textAlign};
  text-align: ${(props: ITextStyleParams) => props.textAlign};
  display: ${(props: ITextStyleParams) => props.display};
  font-family: ${(props: ITextStyleParams) => props.fontFamily};
  align-items: center;
  line-height: 1.4;
`;

export default Text;
