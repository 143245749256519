import React, { ReactElement, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { appThemeDark, appThemeLight } from '../styles/themes';
import GlobalStyles from '@mui/material/GlobalStyles';
import { StyleProviderContext } from '../styles';
import { Colors } from 'constants/index';

export type StyleProviderProps = {
  children?: ReactElement | ReactElement[];
};

const localstorageKey = process.env.REACT_APP_THEME_KEY || 'develon-theme';
const defaultTheme = localStorage.getItem(process.env.REACT_APP_THEME_KEY || 'develon-theme') || 'light';

export function StyleProvider({ children }: StyleProviderProps) {
  const [mode, setMode] = useState(defaultTheme);

  const appTheme = useMemo(() => {
    localStorage.setItem(localstorageKey, mode);
    if (mode === 'light') {
      return appThemeLight;
    }
    return appThemeDark;
  }, [mode]);

  return (
    <StyleProviderContext.Provider
      value={{
        mode,
        setMode,
      }}
    >
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            a: { all: 'unset' },
            'html, body': { all: 'unset', fontFamily: 'RightGrotesk-Regular', backgroundColor: Colors.ALICE_BLUE },
            p: { all: 'unset' },
          }}
        />
        {children}
      </ThemeProvider>
    </StyleProviderContext.Provider>
  );
}

export default StyleProvider;
