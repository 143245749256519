import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from 'store/hooks';
import Text from 'UiKit/Text';
import { selectCodeDetails } from 'store/code';
import { formatCardNumber } from 'utils/functions';
import ModalWrapper from './modalWrapper';

const NewCardModal = ({ closeModals }: { closeModals: () => void }) => {
  const [t] = useTranslation();
  const codeData = useAppSelector(selectCodeDetails);

  return !codeData?.code ? (
    <CircularProgress color={'primary'} size={40} />
  ) : (
    <ModalWrapper
      title={t('newCodeModalTitle')}
      closeModals={closeModals}
      onSubmit={closeModals}
      btnText="ok"
      showCancelBtn={false}
    >
      <Text textAlign={'center'}>{t('newCodeModalMessage', { code: formatCardNumber(codeData.code.toString()) })}</Text>
      <Text textAlign={'center'}>{t('newCodeModalSubMessage')}</Text>
    </ModalWrapper>
  );
};
export default NewCardModal;
