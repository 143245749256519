import AppConfigs from 'app-configs';
import { BrowserRouter } from 'react-router-dom';
import { RouterProvider } from './providers';
import GlobalStyle from 'assets/fonts/fonts';
import Header from 'components/header';
import RouterGuard from 'providers/routeGuard';

function App() {
  return (
    <BrowserRouter>
      <AppConfigs>
        <GlobalStyle />
        <Header />
        <RouterGuard>
          <RouterProvider />
        </RouterGuard>
      </AppConfigs>
    </BrowserRouter>
  );
}

export default App;
