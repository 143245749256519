import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllMuseums, selectMuseumData, selectMuseumIsFetching } from 'store/museum';
import { Colors } from 'constants/index';
import { getMuseumInfo } from 'store/museum/asyncActions';
import { resetStatistics } from 'store/statistics';
import { deleteCodeDetails } from 'store/code';
import { resetRatings } from 'store/ratings';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

const MuseumList = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const museumData = useAppSelector(selectMuseumData);
  const loading = useAppSelector(selectMuseumIsFetching);
  const allMuseums = useAppSelector(selectAllMuseums);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (museumData) {
      setValue(museumData.data.id);
    } else setValue('');
  }, [museumData, allMuseums]);

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value },
    } = event;
    const museum = allMuseums.find(item => item.id === value);
    setValue(value);
    sessionStorage.setItem('MuseumSelected', 'true');
    sessionStorage.setItem('Auth', museum.token);
    dispatch(getMuseumInfo());
    dispatch(resetStatistics());
    dispatch(resetRatings());
    dispatch(deleteCodeDetails());
  };

  return (
    <AutoCompleteContainer>
      <Select
        displayEmpty
        value={value || ''}
        onChange={handleChange}
        input={
          <OutlinedInput
            className="outlineInput"
            endAdornment={loading && <CircularProgress color={'primary'} className="loadingIcon" size={20} />}
          />
        }
        renderValue={selected => {
          if (!selected) {
            return <em>{t('selectMuseum')}</em>;
          }
          const museum = allMuseums.find(item => item.id === selected);
          return `${museum?.name} ${museum?.id}`;
        }}
        MenuProps={MenuProps}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem disabled value="">
          <em>{t('selectMuseum')}</em>
        </MenuItem>
        {allMuseums.map((museum: any, index: number) => (
          <MenuItem key={index} value={museum.id}>
            {museum.name} {museum.id}
          </MenuItem>
        ))}
      </Select>
    </AutoCompleteContainer>
  );
};

export default MuseumList;

const AutoCompleteContainer = styled(FormControl)`
  width: 100% !important;
  height: 72px !important;

  .MuiOutlinedInput-root {
    background-color: ${Colors.WHITE} !important;
    width: 100% !important;
    height: 72px !important;
    display: flex !important;
    border-radius: 10px !important;
    border: none !important;
    box-sizing: border-box !important;
    outline: none !important;
    box-shadow: inset 0px 1px 2px ${Colors.TRANSPARENT_TOLOPEA} !important;
    color: ${Colors.TOLOPEA} !important;
    .MuiSelect-select {
      width: 90%;
      padding: 0px;
      padding-left: 10px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .loadingIcon {
      margin-right: 15px;
    }
  }
`;
