import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectMuseumData } from 'store/museum';
import ModalComponent from 'UiKit/Modal';
import Text from 'UiKit/Text';
import { FontFamily, FontSize, Status } from 'constants/index';
import { selectLoginStatus } from 'store/auth';
import { logoutRequestAsync } from 'store/auth/asyncActions';

const Header = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState<boolean>(false);
  const museumData = useAppSelector(selectMuseumData);
  const loginStatus = useAppSelector(selectLoginStatus);

  const handleShowPinCode = () => {
    if (!museumData) {
      if (!museumData) {
        enqueueSnackbar(t('selectAMuseumFirst'), {
          variant: 'error',
        });
      }
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <BodyWrapper>
        <Text id="main" onClick={() => navigate('/')}>
          {t('main')}
        </Text>
        <Text id="viewStatistics" onClick={() => navigate('/statistics')}>
          {t('viewStatistics')}
        </Text>
        {loginStatus === Status.SUCCEEDED && (
          <Text id="viewRatings" onClick={() => navigate('/ratings')}>
            {t('viewRatings')}
          </Text>
        )}
        <Text id="printQr" onClick={() => navigate('/qr')}>
          {t('printQrCode')}
        </Text>
        <Text id="showPinCode" onClick={handleShowPinCode}>
          {t('showPinCode')}
        </Text>
      </BodyWrapper>
      {loginStatus === Status.SUCCEEDED && (
        <Text
          id="logout"
          onClick={() => {
            dispatch(logoutRequestAsync());
            navigate('/login');
          }}
        >
          {t('logout')}
        </Text>
      )}
      <ModalComponent isVisible={showModal} setVisible={() => setShowModal(false)}>
        <Text fontFamily={FontFamily.BOLD} fontSize={FontSize.TWENTY_EIGHT}>
          {museumData?.data?.pin_code}
        </Text>
      </ModalComponent>
    </>
  );
};

export default Header;

const BodyWrapper = styled.div`
  padding-left: 60px;
  display: flex;
  gap: 20px;
`;
