import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Colors, FontSize } from 'constants/index';
import { returnSessionStorageValues } from 'utils/functions';

const HeaderGuard = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const museumToken = useMemo(() => urlParams.get('token'), [urlParams]);

  useEffect(() => {
    let header: any = document.getElementById('header');

    const { token } = returnSessionStorageValues();
    if (museumToken) {
      header.classList.remove('hidden');
      navigate('/');
    }
    if (!token && !museumToken) {
      header.classList.add('hidden');
      navigate('/login');
    } else {
      header.classList.remove('hidden');
    }
  }, [museumToken, navigate]);

  return (
    <Container className="no-print" id="header">
      {children}
    </Container>
  );
};

export default HeaderGuard;

const Container = styled.div`
  width: 100%;
  height: 120px;
  background-color: #2c253d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    font-size: ${FontSize.EIGHTEEN};
    cursor: pointer;
    color: ${Colors.FRENCH_ROSE};
  }
  > div:last-child {
    padding-right: 60px;
  }
`;
