export enum ElementTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export type priceType = {
  card?: number;
  renewal_after_expiry?: number;
  renewal_before_expiry?: number;
};

export type AppLoginForm = {
  email: string;
  password: string;
};
