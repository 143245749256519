import { createAsyncThunk } from '@reduxjs/toolkit';
import { WebService } from 'api/main';

export const getStatistics = createAsyncThunk('web/statistics', async (_, { rejectWithValue }) => {
  try {
    const response = await WebService.webStatisticsEventCalendar();
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});
