import { createAsyncThunk } from '@reduxjs/toolkit';
import { WebService } from 'api/main';

export const getQrCode = createAsyncThunk('web/museum/qr', async (_, { rejectWithValue }) => {
  try {
    const response = await WebService.webMuseumGetQr();
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
