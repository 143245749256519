import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FontSize, FontWeight, FontFamily, Colors } from 'constants/index';
import Text from 'UiKit/Text';
import { CSSProperties } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  label?: string;
  columns: any[];
  data: any[];
  style?: CSSProperties;
  isLoading?: boolean;
}

const RenderTable = ({ label, columns, data, style, isLoading }: Props) => {
  const [t] = useTranslation();

  return (
    <Container style={{ ...style, position: 'relative' }}>
      {label && (
        <Text textAlign="left" fontSize={FontSize.TWENTY} fontWeight={FontWeight.BOLD} fontFamily={FontFamily.BOLD}>
          {t(label)}
        </Text>
      )}
      <table style={style}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} style={column.style}>
                {t(column.label)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {columns.map((column, indx) => (
                <td key={indx} style={column.style}>
                  {column.render ? column.render(item[column.key], item) : item[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading && (
        <LoadingContainer>
          <CircularProgress color={'primary'} size={60} />
        </LoadingContainer>
      )}
    </Container>
  );
};

export default RenderTable;

const Container = styled.div`
  > div:first-child {
    margin-bottom: 10px;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  td {
    padding: 10px;
    text-align: left;
  }

  th {
    padding: 10px;
    text-align: left;
    background: ${Colors.LIGHT_GREY};
  }
`;

const LoadingContainer = styled.span`
  position: absolute;
  height: 100%;
  top: 0px;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
