import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import styled from 'styled-components';
import Text from 'UiKit/Text';
import Button from 'UiKit/Button';
import Loading from 'UiKit/Loading';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { FontSize, FontWeight, FontFamily, Colors } from 'constants/index';
import { selectMuseumData } from 'store/museum';
import {
  resetDeleteErrorMessage,
  resetDeleteSuccessMessage,
  selectAverageRatings,
  selectRatingDeleteErrorMessage,
  selectRatingDeleteSuccessMessage,
  selectRatingDownloadsFetching,
  selectRatingsData,
  selectRatingsStatus,
} from 'store/ratings';
import { downloadRatings, getRatings } from 'store/ratings/asyncActions';
import RatingsList from './ratings';

const Ratings = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const ratingsData = useAppSelector(selectRatingsData);
  const ratingsStatus = useAppSelector(selectRatingsStatus);
  const averageRating = useAppSelector(selectAverageRatings);
  const museumData = useAppSelector(selectMuseumData);
  const downloadIsFetching = useAppSelector(selectRatingDownloadsFetching);
  const errorMessage = useAppSelector(selectRatingDeleteErrorMessage);
  const successMessage = useAppSelector(selectRatingDeleteSuccessMessage);

  useEffect(() => {
    if (!museumData) {
      enqueueSnackbar(t('selectAMuseumFirst'), {
        variant: 'error',
      });
      navigate('/');
    } else {
      if (!ratingsStatus) {
        dispatch(getRatings({ museumId: museumData.data.id })).then(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  }, [enqueueSnackbar, navigate, museumData, t, dispatch, ratingsStatus]);

  useEffect(() => {
    if (successMessage) {
      enqueueSnackbar(t(successMessage), {
        variant: 'success',
      });
      dispatch(resetDeleteSuccessMessage());
    }
  }, [successMessage, dispatch, enqueueSnackbar, t]);

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      dispatch(resetDeleteErrorMessage());
    }
  }, [errorMessage, dispatch, enqueueSnackbar]);

  return loading ? (
    <Loading />
  ) : (
    <BodyWrapper>
      <Text fontSize={FontSize.THIRTY_TWO} fontWeight={FontWeight.BOLD} fontFamily={FontFamily.BOLD}>
        {museumData.data.name} {museumData.data.id}
      </Text>
      <HeaderWrapper>
        <InfoWrapper>
          <Text fontSize={FontSize.TWENTY}>{`${t('averageRating')}: ${averageRating.museum_average_ratings}`}</Text>
          <Text fontSize={FontSize.TWENTY}>{`${t('totalComments')}: ${ratingsData?.meta.total || 0}`}</Text>
        </InfoWrapper>
        <Button
          size="small"
          containerStyle={{
            paddingRight: 10,
            paddingLeft: 10,
            marginRight: 10,
            background: Colors.DODGER_BLUE,
            border: 'none',
          }}
          text={'download'}
          leftIcon={<DownloadIcon htmlColor={Colors.WHITE} />}
          onClick={() => dispatch(downloadRatings(museumData.data.id))}
          isLoading={downloadIsFetching}
        />
      </HeaderWrapper>
      <RatingsList />
    </BodyWrapper>
  );
};

export default Ratings;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  .noRating {
    margin-top: 30px;
    font-style: italic;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  background: ${Colors.LIGHT_BLUE};
  padding: 15px 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
`;
