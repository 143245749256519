import { Action, configureStore, ThunkAction, combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth';
import museumReducer from './museum';
import codeReducer from './code';
import statisticsReducer from './statistics';
import qrReducer from './qr';
import ratingsReducer from './ratings';

const combinedReducer = combineReducers({
  auth: authReducer,
  museum: museumReducer,
  codes: codeReducer,
  statistics: statisticsReducer,
  qr: qrReducer,
  ratings: ratingsReducer,
});

const rootReducer = (state: any, action: any) => {
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
