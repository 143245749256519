import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { WebService } from 'api/main';
// import { Code } from './modal/codes';

export const getCodes: AsyncThunk<any, { query: string; page?: number }, {}> = createAsyncThunk(
  'web/code/search',
  async ({ query, page = 1 }, { rejectWithValue }) => {
    try {
      const response = await WebService.webCodeSearch(query, 'asc', 10, page);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.body);
    }
  }
);

export const getCodeDetails = createAsyncThunk('web/code', async (code: number, { rejectWithValue }) => {
  try {
    const response = await WebService.webCodeExtendedDetails({ code });
    return response;
  } catch (error: any) {
    return rejectWithValue(error.body);
  }
});

export const getCodePrice = createAsyncThunk('web/code/price', async (_, { rejectWithValue }) => {
  try {
    const response = await WebService.webPriceCardEventCalendar();
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const renewCode: AsyncThunk<any, number, {}> = createAsyncThunk(
  'web/code/renew',
  async (code: number, { rejectWithValue }) => {
    try {
      const response = await WebService.webCodeRenew({ code });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.body);
    }
  }
);

export const markVisit: AsyncThunk<any, { code: number; info: string }, {}> = createAsyncThunk(
  'web/visit',
  async ({ code, info }, { rejectWithValue }) => {
    try {
      const response = await WebService.webVisitCreate({ code, info });
      return {
        [`visits_${info}`]: true,
        expires_at: response.expires_at ? new Date(response.expires_at).toISOString() : null,
      };
    } catch (error: any) {
      return rejectWithValue(error.body);
    }
  }
);

export const mergeCards: AsyncThunk<any, any, {}> = createAsyncThunk(
  'web/card/merge',
  async (body: any, { rejectWithValue, getState }) => {
    const state: any = getState();
    try {
      let params = {
        ...body,
        museum_id: state.museum.data.data.id,
        source: 'eventCalendar',
      };
      const lang = localStorage.getItem('i18nextLng') || 'en';
      const response = await WebService.webCodeMerge(params, lang);
      let validFor = state.codes.codeData.data.valid_for;
      let ExpiredDate, newValidFor, extendedBy;
      if (response.newExpiry) {
        ExpiredDate = new Date(response.newExpiry.split('-').reverse().join('-')).toISOString();
      } else {
        newValidFor = response.duration;
        extendedBy = newValidFor - validFor;
      }
      return { expires_at: ExpiredDate || null, valid_for: newValidFor || validFor, extendedBy };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const markSold = createAsyncThunk(
  'web/card/mark-sold',
  async (code: number, { rejectWithValue, dispatch, getState }) => {
    const state: any = getState();
    try {
      let params = {
        code,
        museum_id: state.museum.data.data.id,
        source: 'eventCalendar',
      };
      await WebService.webCodeMarkSold(params);
      return dispatch(getCodeDetails(code));
    } catch (error: any) {
      return rejectWithValue(error.body);
    }
  }
);

export const createNewCode = createAsyncThunk('web/code/new', async (_, { rejectWithValue, dispatch }) => {
  try {
    const response: any = await WebService.webCodeNew();
    dispatch(getCodeDetails(response?.data.code));
    dispatch(getCodes({ query: response?.data.code.toString() }));
    return response;
  } catch (error: any) {
    return rejectWithValue(error.body);
  }
});
