import { useRoutes } from 'react-router-dom';
import Main from 'components/main';
import Statistics from 'components/statistics';
import QrCode from 'components/qr';
import Login from 'components/login';
import Ratings from 'components/ratings';

export const RouterProvider = () => {
  return useRoutes([
    {
      path: '/',
      element: <Main />,
    },
    {
      path: '/statistics',
      element: <Statistics />,
    },
    {
      path: '/qr',
      element: <QrCode />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/ratings',
      element: <Ratings />,
    },
  ]);
};

export default RouterProvider;
