import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Text from 'UiKit/Text';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getStatistics } from 'store/statistics/asyncActions';
import { FontSize, FontWeight, FontFamily } from 'constants/index';
import { selectStatisticsData } from 'store/statistics';
import Today from './today';
import Invoice from './invoice';
import Postal from './postal';
import IntervalDates from './intervalDates';
import { selectMuseumData } from 'store/museum';
import Loading from 'UiKit/Loading';

const Statistics = () => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(true);
  const statisticsData = useAppSelector(selectStatisticsData);
  const museumData = useAppSelector(selectMuseumData);

  useEffect(() => {
    if (!museumData) {
      enqueueSnackbar(t('selectAMuseumFirst'), {
        variant: 'error',
      });
      navigate('/');
    } else {
      if (!statisticsData) {
        dispatch(getStatistics()).then(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
  }, [enqueueSnackbar, navigate, museumData, t, dispatch, statisticsData]);

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <BodyWrapper>
          <Text fontSize={FontSize.THIRTY_TWO} fontWeight={FontWeight.BOLD} fontFamily={FontFamily.BOLD}>
            {statisticsData.payload.museumName}
          </Text>

          <div>
            <Text textAlign="left" fontSize={FontSize.EIGHTEEN}>
              {statisticsData.payload.period}
            </Text>
            <Text textAlign="left" fontSize={FontSize.TWENTY} fontWeight={FontWeight.BOLD} fontFamily={FontFamily.BOLD}>
              {t('invoice')}
            </Text>
            {statisticsData.event_calendar_statistics_issue_message ? (
              <Text textAlign="left" fontSize={FontSize.EIGHTEEN}>
                {statisticsData.event_calendar_statistics_issue_message}
              </Text>
            ) : (
              <Invoice data={statisticsData.invoicing} />
            )}
          </div>
          <Today data={statisticsData.currentDay} />
          <div>
            <Text
              textAlign="left"
              fontSize={FontSize.EIGHTEEN}
              fontWeight={FontWeight.BOLD}
              fontFamily={FontFamily.BOLD}
            >
              {t('bugNoticeTitleFi')}
            </Text>
            <Text textAlign="left" fontSize={FontSize.EIGHTEEN}>
              {t('bugNoticeMessageFi')}
            </Text>
          </div>
          <div>
            <Text
              textAlign="left"
              fontSize={FontSize.EIGHTEEN}
              fontWeight={FontWeight.BOLD}
              fontFamily={FontFamily.BOLD}
            >
              {t('bugNoticeTitleEn')}
            </Text>
            <Text textAlign="left" fontSize={FontSize.EIGHTEEN}>
              {t('bugNoticeMessageEn')}
            </Text>
          </div>
          <IntervalDates data={statisticsData.visits.daily} name="last14days" dateName="date" />
          <IntervalDates data={statisticsData.visits.weekly} name="last12weeks" dateName="week" />
          <IntervalDates data={statisticsData.visits.monthly} name="last12months" dateName="monthyear" />
          <IntervalDates data={statisticsData.visits.yearly} name="last12years" dateName="year" />
          <Postal data={statisticsData.postal} />
        </BodyWrapper>
      )}
    </Container>
  );
};

export default Statistics;

const Container = styled.div`
  padding: 50px;
`;

const ColumnFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyWrapper = styled(ColumnFlexWrapper)`
  gap: 30px;
`;
