import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Text from 'UiKit/Text';
import { selectCodeDetails, selectCodeIsFetching, selectCodePrice } from 'store/code';
import { markSold } from 'store/code/asyncActions';
import ModalWrapper from './modalWrapper';

const MarkSoldModal = ({ closeModals }: { closeModals: () => void }) => {
  const [t] = useTranslation();
  const codeData = useAppSelector(selectCodeDetails);
  const priceData = useAppSelector(selectCodePrice);
  const status = useAppSelector(selectCodeIsFetching);
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    dispatch(markSold(codeData.code));
  };

  return (
    <ModalWrapper
      title={t('markSoldMessage', { code: codeData.code })}
      closeModals={closeModals}
      onSubmit={handleSubmit}
      btnLoding={status}
      btnText="markAsSold"
    >
      <Text textAlign={'center'}>{t('markSoldPriceMessage', { price: priceData.card / 100 })}</Text>
    </ModalWrapper>
  );
};
export default MarkSoldModal;
