export * from './colors';
export * from './fonts';
export * from './types';
export * from './styles';
export const API_BASE_URL =
  process.env.REACT_APP_BASE_URL ?? 'https://ztvvrz9b07.execute-api.eu-central-1.amazonaws.com';
export const Status = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};
