import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'constants/index';
import { RootState } from '../index';
import {
  getCodes,
  getCodeDetails,
  renewCode,
  markVisit,
  mergeCards,
  markSold,
  getCodePrice,
  createNewCode,
} from './asyncActions';

export interface CodeState {
  is_fetching: boolean;
  is_newCode_fetching: boolean;
  data: any;
  status: string | null;
  codeData: any;
  successMessage: string;
  errorMessage: string;
  price: any;
}

const initialState: CodeState = {
  is_fetching: false,
  is_newCode_fetching: false,
  data: null,
  status: null,
  codeData: null,
  successMessage: '',
  errorMessage: '',
  price: null,
};

export const codeSlice = createSlice({
  name: 'code',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    deleteCodes: state => {
      state.data = null;
    },
    deleteMessages: state => {
      state.successMessage = '';
      state.errorMessage = '';
    },
    deleteCodeDetails: state => {
      state.codeData = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCodes.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.data = action.payload;
      })
      .addCase(getCodeDetails.fulfilled, (state, action) => {
        state.is_fetching = false;
        state.codeData = action.payload;
      })
      .addCase(renewCode.fulfilled, (state, action) => {
        let newData = { ...state.codeData };
        newData.data = { ...newData.data, expires_at: action.payload.expires_at };
        state.successMessage = 'cardRenewed';
        state.is_fetching = false;
        state.codeData = { ...newData };
      })
      .addCase(markVisit.fulfilled, (state, action) => {
        let newData = { ...state.codeData };
        newData.data = { ...newData.data, ...action.payload };
        state.successMessage = action.payload?.visits_regular ? 'visitMarked' : 'freeVisitMarked';
        state.is_fetching = false;
        state.codeData = { ...newData };
      })
      .addCase(mergeCards.fulfilled, (state, action) => {
        let newData = { ...state.codeData };
        newData.data = { ...newData.data, ...action.payload };
        state.successMessage = action.payload?.extendedBy ? 'cardMergedExtended' : 'cardMerged';
        state.is_fetching = false;
        state.codeData = { ...newData };
      })
      .addCase(markSold.fulfilled, state => {
        state.successMessage = 'cardSold';
        state.is_fetching = false;
      })
      .addCase(createNewCode.fulfilled, state => {
        state.successMessage = 'newCodeCreated';
        state.is_newCode_fetching = false;
      })
      .addCase(getCodePrice.fulfilled, (state, action) => {
        state.price = action.payload;
      })
      .addCase(mergeCards.rejected, (state, action: any) => {
        if (action.payload.status === 422) {
          state.errorMessage = action.payload.body.errors.gift_code[0];
        } else if (action.payload.status === 400) {
          state.errorMessage = action.payload.body.error.message;
        } else state.errorMessage = action.payload.message || action.payload.error.message;
        state.is_fetching = false;
      })
      .addCase(createNewCode.pending, state => {
        state.is_newCode_fetching = true;
      })
      .addCase(createNewCode.rejected, (state, action: any) => {
        state.errorMessage = action.payload?.message || action.payload?.error.message;
        state.is_newCode_fetching = false;
      })
      .addMatcher(isAnyOf(renewCode.rejected, markVisit.rejected, markSold.rejected), (state, action: any) => {
        state.errorMessage = action.payload.error?.message;
        state.is_fetching = false;
      })
      .addMatcher(isAnyOf(getCodes.rejected, getCodeDetails.rejected), (state, action: any) => {
        state.is_fetching = false;
        state.status = Status.FAILED;
        state.errorMessage = action.payload.message || action.payload?.error.message;
      })
      .addMatcher(
        isAnyOf(
          getCodes.pending,
          getCodeDetails.pending,
          renewCode.pending,
          markVisit.pending,
          mergeCards.pending,
          markSold.pending
        ),
        state => {
          state.is_fetching = true;
        }
      );
  },
});

export const { setStatus, deleteCodes, deleteMessages, deleteCodeDetails } = codeSlice.actions;

export const selectCodes = (state: RootState): CodeState['data'] => state.codes.data;
export const selectCodeIsFetching = (state: RootState): CodeState['is_fetching'] => state.codes.is_fetching;
export const selectNewCodeIsFetching = (state: RootState): CodeState['is_newCode_fetching'] =>
  state.codes.is_newCode_fetching;
export const selectCodeDetails = (state: RootState): CodeState['codeData'] => state.codes.codeData?.data;
export const selectCodeDetailsSuccessMessage = (state: RootState): CodeState['successMessage'] =>
  state.codes.successMessage;
export const selectCodeDetailsErrorMessage = (state: RootState): CodeState['errorMessage'] => state.codes.errorMessage;
export const selectCodePrice = (state: RootState): CodeState['price'] => state.codes.price;
export default codeSlice.reducer;
