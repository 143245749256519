import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from 'constants/index';
import { returnErrorMessageContent } from 'utils/functions';
import { RootState } from '../index';
import { loginRequestAsync, logoutRequestAsync } from './asyncActions';

export interface AuthState {
  is_fetching: boolean;
  status: string | null;
  message: string;
}

const initialState: AuthState = {
  is_fetching: false,
  status: null,
  message: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    deleteErrorMessage: state => {
      state.message = '';
    },
  },

  extraReducers: builder => {
    builder
      .addCase(loginRequestAsync.pending, state => {
        state.is_fetching = true;
      })
      .addCase(loginRequestAsync.fulfilled, state => {
        state.is_fetching = false;
        state.status = Status.SUCCEEDED;
      })
      .addCase(logoutRequestAsync.fulfilled, state => {
        state.is_fetching = false;
        state.status = null;
      })
      .addCase(loginRequestAsync.rejected, (state, action: any) => {
        state.is_fetching = false;
        state.status = Status.FAILED;
        // state.message = 'asd';
        state.message = action.payload?.error
          ? returnErrorMessageContent(action.payload.error)
          : returnErrorMessageContent(action.payload?.errors);
      });
  },
});

export const { setStatus, deleteErrorMessage } = authSlice.actions;

export const selectLoginErrorMessage = (state: RootState): AuthState['message'] => state.auth.message;
export const selectLoginIsFetching = (state: RootState): AuthState['is_fetching'] => state.auth.is_fetching;
export const selectLoginStatus = (state: RootState): AuthState['status'] => state.auth.status;

export default authSlice.reducer;
